//////////////////////////////

module.exports = (type) => {
    var schema = {
        file_name: {
            type: type.STRING(2048),
            allowNull: false,
        },
        hash: {
            type: type.STRING(2048),
            allowNull: true,
        },
        user_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        report: {
            type: type.JSON,
            defaultValue: {},
        },
        txtpath: {
            type: type.STRING(2048),
            allowNull: true,
        },
        audiopath: {
            type: type.STRING(2048),
            allowNull: true,
        },
        videopath: {
            type: type.STRING(2048),
            allowNull: true,
        },
        msg: {
            type: type.STRING(2048),
            allowNull: true,
        },
        status: {
            type: type.STRING(2048),
            allowNull: false,
            defaultValue: 'queue',
        },
        created: {
            type: type.DATE,
            defaultValue: type.NOW,
            allowNull: false,
        },
        file: {
            type: type.VIRTUAL,
            file: true,
            progress: true,
            allowNull: false,
            max_size: 2500000000,
            mime_types: [ 'video/*' ],
            //filter: 'images'
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        deleted: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },

    };

    return schema;
}


