<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    props: [ 'placeholder', ],
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true ) {
                if ( this.get_step() === 'any' ) {
                    check = this._validate_integer();
                } else {
                    check = this._validate_decimal();
                }
            }
            if ( check === true ) {
                if ( this.maxvalue ) {
                     check = this._validate_max_value();
                }
            }
            return check;
        },
        _return_model() { // sovrascrivo fa finz del parent per accettare i valori 0
            if ( this.model === null || this.model === '' || typeof this.model === 'undefined' ) { return null; }
            return this.model;
        },
        _validate_integer() {
            if ( this.model && String( this.model ) !== String( parseInt( this.model ) ) ) { // qui controllo ancora se il valore è presente, da rivedere
                this.error_component = true;
                this.error_message = 'il valore non sembra essere un intero valido';
                return false;
            }
            return true;
        },
        _validate_max_value() {
            if ( this.model > this.maxvalue ) {
                this.error_component = true;
                this.error_message = `il valore massimo è ${ this.maxvalue }`;
                return false;
            }
            return true;
        },
        _validate_decimal() {
            if ( isNaN( parseFloat( this.model ) ) === true ) { // qui controllo ancora se il valore è presente, da rivedere
                this.error_component = true;
                this.error_message = 'il valore non sembra essere un numero decimale valido';
                return false;
            }
            this.model = parseFloat( this.model ).toFixed( 2 );
            return true;
        },
        get_step() {
            if ( !this.decimal ) { return 'any'; }
            if ( this.decimal === 1 ) { return '.1'; }
            else if ( this.decimal === 2 ) { return '.01'; }
            return 'any';
        }
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name" class="col-form-label" :class="label_class">{{ get_label() }}</label>
        <b-input
            v-on:keyup.enter  = "enter_key"
            ref               = "input"
            type              = "number"
            class             = "form-control"
            :size             = "input_class"
            v-bind:class      = "{ 'is-invalid': error_component }"
            :placeholder      = "get_placeholder()"
            :aria-label       = "name"
            :aria-describedby = "name"
            :id               = "'bnv-form-' + name"
            v-model           = "model"
            :step             = "get_step()"
            :disabled         = "disabled"
            :readonly         = "_readonly"
        />
        <small v-if="helper"  :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>




