<script>

const nprogress = require('nprogress');
const config = require('@/config')[ process.env.NODE_ENV ];
import { router } from '@/helpers';

export default {
    components: {
    },
    computed: {
        icon_url() { return config.backend_url + 'icons/'; },
        selected: {
            get() { return this.$store.state.route.route; },
            set( value ) { this.$store.dispatch('route/set_params',  { route: value }) },
        },
    },
    created () {
    },
    methods: {
        go_to_page( type ) {
            type === 'web' ? this.navigate_to('/list/rwreport') : this.navigate_to('/list/extraction');
        },
        navigate_to(route) {
            if ( route !== this.selected ) {
                this.selected = route;
                this.$router.push( route );
            }
        },
    },
    data () {
        return {
        }
    },
    mounted () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            nprogress.done(true);
        })
    },
};

</script>

<template>

    <div>
        <div style="height: 100px"></div>
        <b-row style="">
            <b-col class="text-center">
                <b-img :src="icon_url + 'video.png'" style="width: 200px; cursor: pointer;" @click="go_to_page('video')"  alt="Circle image"></b-img>
                <h3 style="margin-top: 20px;">Rassegna Video</h3>
            </b-col>
            <b-col class="text-center">
                <b-img :src="icon_url + 'web.png'" style="width: 200px; cursor: pointer;" @click="go_to_page('web')" alt="Circle image"></b-img>
                <h3 style="margin-top: 20px;">Rassegna Web</h3>
            </b-col>
        </b-row>
    </div>

</template>


