/////////////////////////////

import { authHeader } from '@/helpers';
const moment = require('moment');
const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];
const months = {
    '01': 'gennaio', '02': 'febbraio', '03': 'marzo', '04': 'aprile', '05': 'maggio', '06': 'giugno', '07': 'luglio',
    '08': 'agosto', '09': 'settembre', '10': 'ottobre', '11': 'novembre', '12': 'dicembre',
};


export class filters {

    static no_format( string ) {
        return string;
    }

    static convert_boolean( bool ) {
        return bool === true ? 'Sì' : 'No';
    }

    static array_to_rows( array ) {
        var string = '<small>';
        string +=  array.length > 0 ? array.join( '<br/>' ) : '';
        string += '</small>';
        return string;
    }

    static array_to_rows_t2( array ) {
        if ( array.length !== 2 ) { return filters.array_to_rows( array ); }
        var contacts = array[1].replace(/\|/g, '<br/>');
        var string = '';
        string += '<p style="line-height: 16px; margin-bottom: 2px;"><small style="font-weight: 600;">' + array[0] + '</small><br/>';
        string += '<small>' + contacts + '</small></p>';
        return string;
    }

    static count_items( obj ) {
        return obj.length;
    }

    static format_datetime( string ) {
        return moment(string).format('DD/MM/YYYY - HH:mm');
    }

    static format_datetime_t2( string ) {
        var date = moment(string);
        var array = [
            date.format('DD/MM/YYYY'),
            date.format('HH:mm:ss'),
        ];
        return filters.array_to_rows_t2( array );
    }

    static format_date( string ) {
        return moment(string).format('DD/MM/YYYY');
    }

    static format_date_extended( string ) {
        var tokens = moment(string).format('DD/MM/YYYY').split('/');
        return [ tokens[0], months[ tokens[1] ] ].join(' ');
    }

    static rowclass ( item, type ) { // moved
        var user_id = 1;
        if (item && type === 'row') {
            if ( item.users_related.includes( user_id ) ) {
                return 'read'
            } else {
                return null
            } 
        } else if ( item && type === 'card' ) {
            if ( item.users_related.includes( user_id ) ) {
                return 'secondary';
            } else {
                return null
            } 
        } else {
            return null
        }
    }

    static linkfunction( url, item ) {
        var url = `${config.backend_url}article/${ item.id }/user`;
        var payload = { user_id: 1 };
        var options = {
            method  : 'POST',
            headers : { 'Content-Type': 'application/json', 'Accept': 'application/json', },
            body    : JSON.stringify( payload ),
        };
        return fetch(url, options);
    }

   static initialfilter() {
       //return { customer_id: 1 }; // TODO
       return {};
   }

}
