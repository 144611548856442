////////////////////

import { userService } from '@/services';
import { router }      from '@/helpers';
import base_module     from '@/vue-model-mirror/stores/base.module';
const store  = require('@/store');

var options = {
    model_name    : 'role',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );
var user = JSON.parse(localStorage.getItem('user'));

const state = {
    ...base.state
};

if ( user ) {
    state['status'] = { loggedIn: true };
    state.user      = user;
} else {
    state['status'] = {};
    state.user      = null;
}

const actions = {
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username });

        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                    dispatch('alert/clear', null, { root: true });
                    dispatch('companyall/get_all', { options: { customer_id: user.customer_id } }, { root: true });
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    logout({ dispatch, commit }) {
        var alternative_modules = [
            'authentication', 'userletter', 'perizia', 'report', 'websocket_logger', 'statemanager', 
        ];
        var modules = Object.keys( store.store._modules.root.state ).filter( x => alternative_modules.includes( x ) === false );
        for ( var i = 0; i < modules.length; i++ ) {
            dispatch(`${ modules[ i ] }/clear`, null, { root: true });
        }
        userService.logout();
        commit('logout');
    },
    reset_password( { dispatch }, { email } ) {
        userService.reset_password( email )
            .then( (res) => {
                dispatch('alert/success', res.message, { root: true });
            })
            .catch(error => {
                dispatch('alert/error', error, { root: true });
            });
    },
    save_password( { dispatch }, { jwt, password, confirm_password } ) {
        userService.save_password( jwt, password, confirm_password )
            .then( (res) => {
                dispatch('alert/success', res.message, { root: true });
            })
            .catch(error => {
                dispatch('alert/error', error, { root: true });
            });
    },
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    }
};

export default {
    namespaced : true,
    state,
    actions,
    mutations,
    getters : base.getters,
};

////////////////////

//import { userService } from '@/services';
//import { router }      from '@/helpers';
//
//const user = JSON.parse(localStorage.getItem('user'));
//const initialState = user
//    ? { status: { loggedIn: true }, user }
//    : { status: {}, user: null  };
//
//export const authentication = {
//    namespaced: true,
//    state: initialState,
//    actions: {
//        login({ dispatch, commit }, { username, password }) {
//            commit('loginRequest', { username });
//
//            userService.login(username, password)
//                .then(
//                    user => {
//                        commit('loginSuccess', user);
//                        router.push('/list/pratica'); // TODO
//                        dispatch('alert/clear', null, { root: true });
//                        dispatch('userletter/get_all', null, { root: true });
//                    },
//                    error => {
//                        commit('loginFailure', error);
//                        dispatch('alert/error', error, { root: true });
//                    }
//                );
//        },
//        logout({ dispatch, commit }) {
//            var modules = [
//                'pratica', 'dati_pratica',
//   
//            ];
//            for ( var i = 0; i < modules.length; i++ ) {
//                dispatch(`${ modules[ i ] }/clear`, null, { root: true });
//            }
//            userService.logout();
//            commit('logout');
//        },
//        reset_password( { dispatch }, { email } ) {
//            userService.reset_password( email )
//                .then( (res) => {
//                    dispatch('alert/success', res.message, { root: true });
//                })
//                .catch(error => {
//                    dispatch('alert/error', error, { root: true });
//                });
//        },
//        save_password( { dispatch }, { jwt, password, confirm_password } ) {
//            userService.save_password( jwt, password, confirm_password )
//                .then( (res) => {
//                    dispatch('alert/success', res.message, { root: true });
//                })
//                .catch(error => {
//                    dispatch('alert/error', error, { root: true });
//                });
//        }
//    },
//    mutations: {
//        loginRequest(state, user) {
//            state.status = { loggingIn: true };
//            state.user = user;
//        },
//        loginSuccess(state, user) {
//            state.status = { loggedIn: true };
//            state.user = user;
//        },
//        loginFailure(state) {
//            state.status = {};
//            state.user = null;
//        },
//        logout(state) {
//            state.status = {};
//            state.user = null;
//        }
//    }
//}

