<script>

export default {

    name: 'extraction-user',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        user_label() { return this.item.user.api.label }
    },
    methods: {
    },
    created () {
        console.log( this.user_label );
    },

}

</script>

<template>

    <div style="">
        <b-badge v-if="user_label === 'Admin Stayted'" variant="secondary" style="opacity: 0.6;">importer</b-badge>
        <span v-else-if="user_label !== 'Admin Stayted'">{{ user_label }}</span>
    </div>

</template>


