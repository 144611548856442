<script>

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default {

    name: 'extraction-download',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        url() {
            return `${ config.backend_url }model/extraction/download/${ this.item.id }`;
        }, 
    },
    methods: {
        download_video() {
            console.log('ciao');
        }
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <a :href="url" target="_blank">
            <icon name="download"/>
        </a>
    </div>

</template>


