<script>

export default {

    name: 'company-selector',
    props: [ 'select_first', 'add_empty_option', 'value', 'report'  ],
    watch: {
        selected(new_val, old_val) {
            if ( new_val != this.value ) { this.$emit('change', new_val); }
        },
        loading( new_val, old_val ) {
            if ( old_val === true && new_val === false ) { this.set_value(); }
        },
    },
    created() {
        this.selected = null;
        this._select_first = typeof this.select_first === 'undefined' ? true : this.select_first;
        this._add_empty_option = typeof this.add_empty_option === 'undefined' ? false : this.add_empty_option;
            setTimeout( () => {
                this.set_value();
            }, 750);
    },
    data() {
        return {
            selected          : null,
            _select_first     : null,
            _add_empty_option : null,
            input_key         : 0,
            setted            : false,
        }
    },
    computed: {
        main_loader() {
            return this.loading === false && this.setted === true;
        },
        loading() { return this.$store.state.companyall.loading },
        items () {
            if ( this.$store.state.companyall.items === null ) {
                this.$store.dispatch('companyall/get_all');
                return [];
            }
            var items = this.$store.state.companyall.items;
            var total_words = 0;
            Object.keys( this.report ).map( x => { total_words += this.report[ x ].occurencies } );
            var options = this._add_empty_option === true ? [ { text: `Tutte le occorrenze (${ total_words })`, value: 0 } ] : [];
            return options.concat( items.map( company => { return { text: company.label, value: company.id } } )
                        .filter( item => {
                            if ( this.report.hasOwnProperty( String( item.value ) ) && this.report[ String( item.value ) ].occurencies > 0 ) {
                                var text = item.text.replace( / \(\d+\)$/, '' );
                                text += ` (${ this.report[ String( item.value ) ].occurencies })`;
                                item.text = text;
                                return item;
                            }
                        }));
        },
    },
    methods: {
        set_value() {
            if ( this.items.length === 0 ) { this.setted = true; this.selected = 0; /*console.log('ciao');*/ return; }
            if ( typeof this.value !== 'undefined' && this.value !== null && this.items.map( x => x.value ).includes( parseInt( this.value ) ) ) {
                this._select_first = false;
                this.selected = this.value;
            }
            if ( this._select_first === true ) {
                var items = this.items;
                this.selected = items[0].value;
            }
            this.setted = true;
        },
    },
}

</script>

<template>

    <div>
        <div v-if="main_loader && items.length > 0">
            <b-form-select :key="input_key" v-model="selected" :options="items" size="sm"></b-form-select>
        </div>
        <div class="form-group" v-if="main_loader === false">
            <input
                type        = "text"
                class       = "form-control spinner"
                placeholder = "caricamento opzioni..."
                id          = "bnv-form-loader-company"
                :disabled   = "true"
            />
        </div>
        <div class="form-group" v-if="main_loader && items.length === 0">
            <input
                type        = "text"
                class       = "form-control spinner"
                placeholder = "nessuna occorrenza"
                id          = "bnv-form-loader-company"
                :disabled   = "true"
            />
        </div>
    </div>

</template>

