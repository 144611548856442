<script>

const nprogress = require('nprogress');

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]
const packagejson = require('@/../package.json');

export default {
    data () {
        return {
            username  : '',
            password  : '',
            submitted : false,
            email     : '',
            version   : packagejson.version,
        }
    },
    mounted () {
        this.$nextTick(function () {
            // Code that will run only after the
            // entire view has been rendered
            nprogress.done(true);
        })
    },
    computed: {
        loggingIn () {
            return this.$store.state.authentication.status.loggingIn;
        },
        alert () {
            return this.$store.state.alert;
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
    },
    created () {
        // reset login status
        this.$store.dispatch('authentication/logout');
    },
    methods: {
        handleSubmit (e) {
            this.submitted = true;
            const { username, password } = this;
            const { dispatch } = this.$store;
            if (username && password) {
                dispatch('authentication/login', { username, password });
            }
        },
        reset_password() {
            this.$store.dispatch('authentication/reset_password', { email: this.email });
        }
    },
    components: {
    }
};

</script>

<template>
 
        <b-container>
            <b-col sm="10" md="7" lg="4" style="margin: auto;">
                <b-card
                    v-bind:style="color ? 'border: 2px solid ' + color : ''"
                    style="padding-top: 20px; padding-left: 20px; padding-right: 20px; padding-bottom: 0px;"
                    >
                    <b-row>
                        <b-col>
                            <div v-if="icon_url" class="text-center">
                                <b-img :src="icon_url" thumbnail fluid rounded="circle" width="120" alt="customer logo"></b-img>
                            </div>
                            <h4
                                class="text-center"
                                style="font-weight: 700; margin-bottom: 30px; margin-top: 10px;"
                                v-bind:style="color ? 'color: ' + color : ''"
                                >
                                {{ app_label }}
                            </h4>
                            <form class="form-signin" @submit.prevent="handleSubmit">
                                <div class="login-wrap" style="margin-top: 15px">
                                    <b-form-input type="text" v-model="username" name="username" class="form-control" :class="{ 'is-invalid': submitted && !username }" placeholder="Email" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit" autofocus></b-form-input>
                                    <div v-show="submitted && !username" class="invalid-feedback">Email is required</div>
                    
                                    <b-form-input type="password" v-model="password" name="password" class="form-control" :class="{ 'is-invalid': submitted && !password }" placeholder="Password" style="margin-bottom: 10px;" @keydown.enter.native="handleSubmit"></b-form-input>
                                    <div v-show="submitted && !password" class="invalid-feedback">Password is required</div>
                    
                                    <label class="checkbox">
                                        <input type="checkbox" value="remember-me"> Remember me
                                    </label>
                                    <!-- <span class="pull-right">
                                        <a data-toggle="modal" href="#myModal"> Forgot Password?</a>
                                    </span> -->
                    
                                    <div class="text-center" style="margin-top: 15px; margin-bottom: 15px;">
                                        <b-button variant="outline-success" :disabled="loggingIn" role="submit" @click="handleSubmit">Sign in</b-button>
                                    </div>
                                </div>
                            </form>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col>
                            <div class="alert alert-info text-center" role="alert" v-if="alert.type === 'valid-feedback'">
                                {{ alert.message }}
                            </div>
                            <div class="alert alert-danger text-center" role="alert" v-if="alert.type === 'invalid-feedback'">
                                {{ alert.message }}
                            </div>
                        </b-col>
                    </b-row>
    
                    <!-- Modal -->
                    <div aria-hidden="true" aria-labelledby="myModalLabel" role="dialog" tabindex="-1" id="myModal" class="modal fade">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h4 class="modal-title">Forgot Password ?</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <p>Enter your e-mail address below to reset your password.</p>
                                    <div class="form-group">
                                        <input v-model="email" type="text" name="email" placeholder="Email" autocomplete="off" class="form-control placeholder-no-fix">
                                        <small id="reset-password-help" class="form-text text-muted">
                                             {{ alert.message }}
                                        </small>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button data-dismiss="modal" class="btn btn-default" type="button">Cancel</button>
                                    <button @click="reset_password()" class="btn btn-success" type="button">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- modal -->

                </b-card>
                <div class="text-right"><small><em>vers. {{version}}</em></small></div>
            </b-col>
        </b-container>

</template>

