////////////////////

import Vue    from 'vue';
import Router from 'vue-router';

import login                  from '@/login';
import home                   from '@/home';
import extraction_page        from '@/extraction/page';
import extraction_list        from '@/extraction/list';
import extraction_form        from '@/extraction/form';
import keyword_list           from '@/keyword/list';
import rwkeyword_list         from '@/rwkeyword/list';
import rwreport_list          from '@/rwreport/list';
import { listpage, formpage } from '@/vue-model-mirror'

const store = require('@/store');


const NProgress = require('nprogress');

Vue.use(Router);


    function sleep(ms) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/login', component: login },
        { path: '/home', component: home },

        { path: '/page/extraction/:id', component: extraction_page },
        { path: '/list/extraction', component: extraction_list, props: { schema_from_route: 'extraction' } },
        { path: '/list/keyword', component: keyword_list, props: { schema_from_route: 'keyword' } },
        { path: '/list/rwkeyword', component: rwkeyword_list, props: { schema_from_route: 'rwkeyword' } },
        { path: '/list/rwreport', component: rwreport_list },
        { path: '/list/:schema', component: listpage },
        { path: '/form/extraction', component: extraction_form, props: { schema_from_route: 'extraction' } },
        { path: '/form/:schema/:id?', component: formpage },

        // otherwise redirect to home
        { path: '*', redirect: '/home' }
    ],
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
});

router.beforeResolve( async (to, from, next) => {
    NProgress.start()
    await sleep(100);
    next()
});

router.afterEach((to, from, next) => {
});

router.beforeEach((to, from, next) => {
    const publicPages = [ '/login', '/savepassword' ];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');

    if (authRequired && !loggedIn) {
        return next('/login');
    } else if ( loggedIn ) {
        // se sto ricaricando la pagina e l'utente non è settato in statemanager
    }

    next();
})

