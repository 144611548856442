////////////////////////////////////////

const keyword_label       = require('./keyword/label').default;
const keyword_delete      = require('./keyword/delete').default;

const extraction_title    = require('./extraction/title').default;
const extraction_legend   = require('./extraction/legend').default;
const extraction_counter  = require('./extraction/counter').default;
const extraction_status   = require('./extraction/status').default;
const extraction_error    = require('./extraction/error').default;
const extraction_download = require('./extraction/download').default;
const extraction_user     = require('./extraction/user').default;

const site_link           = require('./site/link').default;

const rwkeyword_label     = require('./rwkeyword/label').default;
const rwkeyword_delete    = require('./rwkeyword/delete').default;
const rwkeyword_stype     = require('./rwkeyword/search_type').default;
const rwkeyword_sword     = require('./rwkeyword/word_type').default;

module.exports = {
    extraction_title,
    keyword_label,
    keyword_delete,
    extraction_legend,
    extraction_counter,
    extraction_status,
    extraction_error,
    extraction_download,
    extraction_user,
    site_link,
    rwkeyword_label,
    rwkeyword_delete,
    rwkeyword_stype,
    rwkeyword_sword,
}

