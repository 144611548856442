<script>

import { router } from '@/helpers';

const config = require('@/config.js')[ process.env.NODE_ENV || 'development' ]

export default {

    data() {
        return {
        }
    },
    computed: {
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
        selected: {
            get() { return this.$store.state.route.route; },
            set( value ) { this.$store.dispatch('route/set_params',  { route: value }) },
        },
    },
    created () {
        //this.send_presence();
    },
    methods: {
        navigate_to(route) {
            if ( route !== this.selected ) {
                this.selected = route;
                this.$router.push( route );
            }
        },
        logout() {
            router.push('/login');
        },
        send_presence() {
            //setTimeout( () => {
                this.$store.dispatch('presence/open');
            //}, 2000);
            //this.send_presence();
        }
    }
}

</script>

<template>

    <div style="margin-bottom: 15px;">
        <b-navbar toggleable="lg" type="dark" :variant="color ? '' : 'info'" fixed="top" v-bind:style="color ? 'background-color:' + color : ''">
            <!-- <b-navbar-brand href="#">
                <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img>
                {{ app_label }}
            </b-navbar-brand> -->

            <b-navbar-brand href="#" @click="navigate_to('/home')">
                <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img>
                &nbsp;<span style="font-weight: 800;">Daily Press</span>
            </b-navbar-brand>

            <b-navbar-toggle class="ml-auto" target="nav-collapse"></b-navbar-toggle>
    
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-navbar-nav v-if="user.role_id === 1">
                        <b-nav-item href="#" @click="navigate_to('/list/customer')">Customers</b-nav-item>
                    </b-navbar-nav>
                     <b-nav-item-dropdown text="Video">
                        <b-dropdown-item href="#" @click="navigate_to('/list/extraction')"><b>ESTRAZIONI</b></b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/company')">Clienti</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/keyword')">Parole Chiave</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/channel')">Canali YouTube</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/site')">Siti</b-dropdown-item>
                     </b-nav-item-dropdown>
                     <b-nav-item-dropdown text="Web">
                        <b-dropdown-item href="#" @click="navigate_to('/list/rwreport')"><b>REPORTS</b></b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/rwcompany')">Clienti</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/rwkeyword')">Parole Chiave</b-dropdown-item>
                     </b-nav-item-dropdown>
                </b-navbar-nav>
    
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#" @click="logout()">logout</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

</template>

