<script>

import components from './components';
const { filters } = require('./filters');

export default {
    name: 'bnvlist',
    props: [
        'schema',
        'storename',
        'fields',
        'primarykey',
        'skip',
        'qryoptions',
        'details',
        'detailsclass',
        'detailsaction',
        'edit',
        'editaction',
        'paginate',
        'link',
        'rowclass',
        'linkfunction',
        'initialfilter',
        'formpath',
        'size',
        'tableattributes',
    ],
    components: { 'detail-card': components.detail_card },
    watch: {
        qry_executed(new_val, old_val) {
            if ( new_val === true && old_val === false ) {
                this.$store.dispatch(`${this.schema}/executed`, false)
                if ( this.started === false ) {
                    this._set_filtered_fields();
                }
            }
        }
    },
    computed: {
        store () {
            return this.$store.state[ this.schema ];
        },
        qry_executed () {
            return this.$store.state[ this.schema ].executed;
        },
        total_rows () {
            return this.$store.state[ this.schema ].tot;
        },
        table_attributes() {
            var default_attributes = {
                'striped'          : true,
                'no-local-sorting' : true,
                'no-sort-reset'    : true,
                'responsive'       : true,
                'selectable'       : false,
                'select-mode'      : 'single',
            };
            var attr_obj = {};
            if (
                    typeof this['tableattributes'] === 'object' &&
                    Object.keys( this['tableattributes'] ).length > 0
                ) {
                Object.keys( this['tableattributes'] ).map( x => { attr_obj[ x ] = this['tableattributes'][ x ]; } );
            } else {
                Object.keys( default_attributes ).map( x => { attr_obj[ x ] = default_attributes[ x ]; } );
            }
            return attr_obj;
        },
        order: {
            get: function() {
                return this.$store.state[ this.schema ].order;
            },
            set: function(value) {
                this.$store.dispatch(`${this.schema}/set_order`, value);
            }
        },
        page: {
            get: function() {
                return this.$store.state[ this.schema ].page;
            },
            set: function(page) {
                this.load_rows( { options: this.get_qry_filters( { page } ) } );
            }
        },
        filter: {
            set: function(filters) {
                this.add_qry_filters( filters );
                this.load_rows( { options: this.get_qry_filters() } );
            },
            get: function() {
                return this.get_qry_filters();
            }
        },
        rows_per_page () {
            return this.$store.state[ this.schema ].rows_per_page;
        },
        initial_sort () {
            if ( this.order !== null ) {
                var field = this.order.split(' ')[0];
                var desc = false
                if ( this.order.endsWith('ASC') ) { desc = false; }
                else if ( this.order.endsWith('DESC') ) { desc = true; }
                return { field, desc };
            }
            return { field: undefined, desc: false };
        },
        table_class() {
            if ( this.size === 'sm' ) { return 'pic-small-table text-xsmall'; };
            return '';
        },
        _edit_on_button() {
            if ( this.edit === true && this.editaction === 'button' ) { return true; }
            return false;
        },
    },
    created () {
        this.started = true;
        this.filters = filters;
        if ( this.initialfilter ) {
            this.add_qry_filters( this.initialfilter );
        }
        if ( this.$store.state[ this.schema ].force_reload === true || this.$store.state[ this.schema ].items === null ) {
            this.started = false;
            //console.log( 'bnvlist/created', Object.keys( this.qryoptions ).length );
            this.load_rows( { options: this.get_qry_filters() } );
            //console.log( 'bnvlist/created', Object.keys( this.qryoptions ).length );
        } else {
            this._set_filtered_fields();
        }

    },
    methods: {
        convert_boolean      : (val) => filters.convert_boolean(val),
        count_items          : (val) => filters.count_items(val),
        format_date          : (val) => filters.format_date(val),
        format_datetime      : (val) => filters.format_datetime(val),
        format_date_extended : (val) => filters.format_date_extended(val),
        array_to_rows        : (val) => filters.array_to_rows(val),

        load_rows : function (options) {
            //console.log( 'bnvlist/load_rows', Object.keys( options ).length );
            this.$store.dispatch(this.schema + '/get_all', options);
            //console.log( 'bnvlist/load_rows', options );
        },
        add_qry_filters: function( filters ) {                                                             // public
            Object.keys( filters ).map( x => { this.qryoptions[ x ] = filters[ x ] });
        },
//      remove_qry_filter: function ( key ) {                                                              // public
//          delete this.qryoptions[ key ];
//      },
        get_qry_filters : function( params = {} ) {                                                        // public
            var conf = this.qryoptions;
            //console.log( 'bnvlist/get_qry_filters', Object.keys( conf ).length );
            Object.keys( params ).map( x => { conf[ x ] = params[ x ] } );
            if ( this.paginate === true ) {
                conf.paginate = true;
            }
            //console.log( 'bnvlist/get_qry_filters', Object.keys( conf ).length );
            return conf;
        },
        get_detail_fields: function() {
            if ( this.detail_fields.length === 0 ) {
                if ( typeof this.fields === 'undefined' ) {
                    this.detail_fields = Object.keys( this.store.items[0] );
                } else {
                    this.detail_fields = this.fields;
                }
            }
            return this.detail_fields;
        },
        go_to_form: function( row ) {
            var id = this.primarykey ? row.item[ this.primarykey ] : row.item.id;
            var form_path = typeof this.formpath !== 'undefined' ? this.formpath : 'form';
            this.$router.push( `/${ form_path }/${this.schema}/${id}` );
        },
        go_to_link: function( row ) {
            if ( this.linkfunction ) {
                this.linkfunction( row.item[ row.field.url ], row.item )
                    .then( res => { this.load_rows( { options: this.get_qry_filters() } ); } );
            }
            this.$router.push( row.item[ this.link ] );
            //window.open(row.item[ row.field.url ], '_blank').focus();
        },
        sort_changed(data) {
            var field = data.sortBy;
            var direction = data.sortDesc ? 'DESC' : 'ASC';
            this.order = [ field, direction ].join(' ');
            this.load_rows( { options: this.get_qry_filters() } );
        },
        _get_field: function ( key ) {
            var obj = null;
            this.fields.map( x => {
                if ( x.key === key ) { obj = x; }
            });
            return obj === null ? key : obj;
        },
        _set_filtered_fields() { // si occupa di distribuire i campi tra colonne e details
            this.filtered_fields = [];
            this.get_detail_fields();
            if ( typeof this.fields === 'undefined' ) {
                var rows = [
                    '\n----------',
                    'nella entry "' + this.schema + '" di listconfig.js non è presente l\'attributo "fields"',
                    '----------',
                ];
                console.error( rows.join('\n\n') );
            }
            this.filtered_fields = this.fields.map( x => {
                if ( x.hasOwnProperty('display') && x.display === false ) {
                        //this.detail_fields.push( x );
                    } else {
                        var column_field = this._get_column_field(x);
                        if ( column_field.hasOwnProperty('htmlformatter') ) {
                            this.html_formatted.push( column_field );
                        } else if ( column_field.hasOwnProperty('component') ) {
                            this.component_formatted.push( column_field );
                        }
                        return column_field;
                }
            });
            if ( this.filtered_fields.indexOf('show_details') === -1 ) {
                if (
                    this.details === true ||
                    //( this.details === true && this.detail_fields.length > 0 ) ||
                    this.edit === true ||
                    this.link !== null
                   ) {
                    var field = { key: 'operative_column', label: '' };
                    //if ( this.link !== null ) { field.link = this.link; }
                    this.filtered_fields.push( field );
                }
                //if ( this.details === true && this.detail_fields.length > 0 ) { this.filtered_fields.push( { key: 'show_details', label: '' } ); }
                //if ( this.edit === true ) { this.filtered_fields.push( { key: 'show_edit', label: '' } ); }
                //if ( this.link !== null ) { this.filtered_fields.push( { key: 'show_link', url: this.link, label: '' } ); }
            }
            this.started = true;
        },
        _get_column_field( field ) {
            var column = Object.assign( {}, field );
            if ( field.hasOwnProperty( 'class' ) && Array.isArray(field.class) ) {
                column.class = [ field.class[0], field.class[1] ].join(' ');
            }
            return column;
        },
        row_selected( rows ) {
            this.$emit('row-selected', rows );
        },
        row_dbl_click(row, index, event ) {
            if ( this.editaction === 'double-click' ) {
                this.go_to_form( { item: row } );
            }
        },
        right_click( row, index, event ) {
            event.preventDefault();
            this.$emit('right-click', { row, event } );
            return false;
        },
        show_details( row, index, event ) {
            if ( this.detailsaction === 'click' ) {
                this.$set(row, '_showDetails', !row._showDetails)
            }
        },
        left_click( row, index, event ) {
            this.numClicks++;
            if (this.numClicks === 1) {          // the first click in .2s
                var self = this;
                setTimeout(function() {
                    switch(self.numClicks) {     // check the event type
                          case 1:
                             //self.row_selected( row, index, event ); // simple click
                             self.show_details( row, index, event ); // simple click
                             break;
                          default:
                             //self.row_dbl_click( row, index, event ); // double click
                             self.row_dbl_click( row, index, event ); // double click
                    }
                    self.numClicks = 0;               // reset the first click
                }, 200);                              // wait 0.2s
            }
        },
        get_html_formatted_key( column ) {
            return `cell(${ column.key })`;
        },
        get_component_formatted_key( column ) {
            return `cell(${ column.key })`;
        },
        get_data( data, key ) {
            var tokens = key.split('.');
            var value = data.item;
            for ( var i = 0; i < tokens.length; i++ ) {
                if ( !value || value.hasOwnProperty( tokens[ i ] ) === false ) { console.error('key non valida per data', { key, data }); }
                value = value[ tokens[ i ] ];
            }
            return value;
        },
        get_html_string(field, data) {
            if ( typeof field.htmlformatter === 'string' ) {
                return filters[ field.htmlformatter ]( this.get_data( data, field.key ) );
            } else if ( typeof field.htmlformatter === 'function' ) {
                return field.htmlformatter( JSON.parse( JSON.stringify( data.item ) ) );
            }
        },
        emit_event( data ) {
            var { event_name, ...options_obj } = data;
            var options = Object.keys( options_obj ).map( x => options_obj[ x ] );
            this.$emit(event_name, ...options );
        }
    },
    data () {
        return {
            detail_fields   : [],
            started         : false,
            filtered_fields : [],
            html_formatted  : [],
            component_formatted: [],
            numClicks : 0,
        }
    },
};

</script>

<template>

    <div>

        <!-- paginator -->
        <div class="overflow-auto" v-if="paginate === true && started === true">
            <b-pagination
                v-model="page"
                :total-rows="total_rows"
                :per-page="rows_per_page"
                :aria-controls="'bnvlist-' + schema"
                align="center"
                size="sm"
            ></b-pagination>
        </div>

        <!-- datatable -->
        <b-table
            v-bind           = "table_attributes"
            :id              = "'bnvlist-' + schema"
            :items           = "store.items"
            :fields          = "filtered_fields"
            :primary-key     = "primarykey"
            :busy            = "store.loading || started === false"
            @sort-changed    = "sort_changed"
            :sort-by         = "initial_sort.field"
            :sort-desc       = "initial_sort.desc"
            :tbody-tr-class  = "rowclass"
            @row-contextmenu = "right_click"
            @row-clicked     = "left_click"
            @row-selected    = "row_selected"
            :class           = "table_class"
            >

            <template v-for="field in html_formatted" v-slot:[get_html_formatted_key(field)]="data">
                <span v-html="get_html_string( field, data )"></span>
                <!-- <span v-html="filters[ field.htmlformatter ]( get_data( data, field.key ) )">{{ field }}</span> -->
            </template>

            <template v-slot:cell(operative_column)="row">
                <div class="text-center">
                <b-button v-if="_edit_on_button" size="sm" @click="go_to_form(row)" variant="light">
                    <icon name="edit"/>
                </b-button>
                <b-button v-if="link !== null" size="sm" @click="go_to_link(row)" variant="light">
                    <icon name="eye"/>
                </b-button>
                <b-button size="sm" @click="row.toggleDetails" variant="light" v-if="details && detailsaction === 'button'" :class="detailsclass">
                    <icon :name="row.detailsShowing ? 'caret-up' : 'caret-down'"/>
                </b-button>
                </div>
            </template>

            <template v-for="cmp in component_formatted" v-slot:[get_component_formatted_key(cmp)]="data">
                <component
                    v-bind:key       = "cmp.name"
                    :name            = "cmp.name"
                    :is              = "cmp.component"
                    :item            = "data.item"
                    @genericevent    = "emit_event"
                ></component>
            </template>

            <template v-slot:row-details="row">
                <detail-card :row="row.item" :display="detail_fields"></detail-card>
            </template>

            <template v-slot:table-busy>
                <div class="text-center">
                    <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </template>

        </b-table>

        <!-- paginator -->
        <div class="overflow-auto" v-if="paginate === true && started === true">
            <b-pagination
                v-model="page"
                :total-rows="total_rows"
                :per-page="rows_per_page"
                :aria-controls="'bnvlist-' + schema"
                align="center"
                size="sm"
            ></b-pagination>
        </div>
    
    </div>

</template>

<style>
    .read {
        font-style: italic;
        opacity: 0.5;
    }
</style>
