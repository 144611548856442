////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';

var options = {
    model_name    : 'route',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters : {},
    route   : '/',
};

const actions = {
    ...base.actions,
};

const mutations = {
    ...base.mutations,
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.route         = '/';
    },
};

const getters = {
    ...base.getters,
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

