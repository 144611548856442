<script>

export default {

    name: 'extraction-title',
    props: [ 'item', ],
    data() {
        return {
            states: {
                queue: {
                    label: 'in coda',
                    variant: 'secondary',
                },
                extracting: {
                    label: 'estrazione',
                    variant: 'warning',
                },
                transcript: {
                    label: 'conversione',
                    variant: 'warning',
                },
                searching: {
                    label: 'ricerca',
                    variant: 'warning',
                },
                success: {
                    label: 'ok',
                    variant: 'success',
                },
                error: {
                    label: 'error',
                    variant: 'danger',
                },
            }
        }
    },
    computed: {
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div>
        <span v-if="!item.config.video_url || item.config.source_type !== 'youtube'" style="font-size: 0.9rem">{{ item.file_name }}<span style="margin-left: 5px;" v-if="item.config.live === true"><b-icon variant="danger" icon="star" aria-hidden="true"></b-icon></span></span>
        <a v-if="item.config.video_url && item.config.source_type === 'youtube'" :href="item.config.video_url" class="extraction-link" target="_blank" style="font-size: 0.9rem">{{ item.file_name }}<span style="margin-left: 5px;" v-if="item.config.live === true"><b-icon variant="danger" icon="star" aria-hidden="true"></b-icon></span></a>
        <span v-if="item.config.source_type === 'youtube' && item.config.channel"><br/><b-badge><b-icon style="margin-top: 2px" variant="light" icon="play-fill" aria-hidden="true"></b-icon> {{ item.config.channel }}</b-badge></span>
    </div>

</template>
