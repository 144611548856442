////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';
import axios from 'axios';

const config = require('../config.js')[ process.env.NODE_ENV ];

var options = {
    model_name    : 'rwassignment',
    send_alert    : false,
    default_order : 'id ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters : {},
    company : null,
    date    : null,
    reset_assignment : false,
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    queue_customer( { commit }, data ) {
        axios.post( config.backend_url + `model/rwqueue/`, data )
            .catch( error => {
                console.log( error );
            });
    },
    reset_assignment( { commit, state }, id ) {
        commit('reset_assignment', { id, payload: { state: 'sending', user_id: null } });
    },
    free_involved_association( { commit, state }, { date, rwcompany_id } ) {
        commit('free_involved_association', { date, rwcompany_id, payload: { state: 'sent' } });
    },
};

const mutations = {
    ...base.mutations,
    success( state, obj ) {
        state.items         = [ obj ];
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = true;
    },
    reset_assignment( state, data ) {
        var map = new Map();
        var items = [];
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === data.id ) {
                var new_item = JSON.parse( JSON.stringify( state.items[ i ] ) );
                Object.keys( data.payload ).map( x => {
                    new_item[ x ] = data.payload[ x ];
                });
                items.push( new_item );
                map.set( new_item.id, new_item );
                continue;
            }
            items.push( state.items[ i ] );
            map.set( state.items[ i ].id, state.items[ i ] );
        }
        state.items = items;
        state.map   = map;
        state.reset_assignment = true;
        setTimeout( () => { state.reset_assignment = false }, 150 );
    },
    free_involved_association( state, data ) {
        var map = new Map();
        var items = [];
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].date === data.date && state.items[ i ].rwcompany_id === parseInt( data.rwcompany_id ) ) {
                var new_item = JSON.parse( JSON.stringify( state.items[ i ] ) );
                Object.keys( data.payload ).map( x => {
                    new_item[ x ] = data.payload[ x ];
                });
                items.push( new_item );
                map.set( new_item.id, new_item );
                console.log( new_item );
                continue;
            }
            items.push( state.items[ i ] );
            map.set( state.items[ i ].id, state.items[ i ] );
        }
        state.items = items;
        state.map   = map;
        state.free_involved_association = true;
        setTimeout( () => { state.free_involved_association = false }, 150 );
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

