////////////////////

import { authHeader } from '@/helpers';

const env    = process.env.NODE_ENV || 'local';
const config = require('@/config.js')[ env ];

export const rwreport_service = {
    replace_pdf,
};

function replace_pdf( id, file ) {
    var url = `${config.backend_url}model/rwreport/${ id }/pdf`;
    var payload = {};
    const form = new FormData();
    form.append('file', file);
    var options = {
        method  : 'PATCH',
        //headers : { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json', },
        headers : authHeader( true ),
        body    : form,
    };
    return fetch(url, options)
        .then(handleResponse)
        .then(msg => {
            return msg;
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload(true);
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

