////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';

var options = {
    model_name    : 'rwkeyword',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    amount_studio : 0,
    amount_users  : 0,
    pras_involved : [],
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
};

const mutations = {
    ...base.mutations,
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};
