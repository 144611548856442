<script>

class counter {

    constructor( company_label, company_id ) {
        this.company_label = company_label;
        this.id = `group_${ company_id }`;
        this.items = [];
        this.errors = 0;
        this.sent   = 0;
    }

    get title() { return this.company_label; }

    get has_error() { return this.errors > 0 ? true : false; }

    get counter() {
        return `${ this.sent } / ${ this.sent + this.errors }`;
    }

    set item( item ) {
        var new_item = { title: item.rwreport.title, id: `item_${ item.id }` };
        if ( item.state === 'sent' ) { 
            this.sent += 1;
        } else {
            this.errors += 1;
            new_item.title     = item.rwreport.title || 'titolo non rilevato';
            new_item.has_error = true;
            new_item.error     = item.config.error || 'errore non definito';
        }
        console.log( new_item );
        this.items.push( new_item );
    }
}

export default {
    name: 'rw-status',
    props: [ 'assignments', 'reports', 'companies', ],
    components: {
    },
    mounted() {
    },
    watch: {
    },
    computed: {
        log() {
            var log = {};
            for ( var i = 0; i < this.reports.length; i++ ) {
                if ( !log.hasOwnProperty( this.reports[ i ].rwcompany_id ) ) {
                    log[ this.reports[ i ].rwcompany_id ] = new counter( this.get_company_label( this.reports[ i ].rwcompany_id ), this.reports[ i ].rwcompany_id );
                }
                log[ this.reports[ i ].rwcompany_id ].item = this.reports[ i ];
            }
            return log;
        }
    },
    methods: {
        get_company_label( id ) {
            for ( var i = 0; i < this.companies.length; i++ ) {
                if ( this.companies[ i ].id === id ) { return this.companies[ i ].label; }
            }
            return 'undefined'
        }
    },
    created() {
    },
    data() {
        return {
        };
    },
};

</script>

<template>
    <b-tabs content-class="mt-3">
        <b-tab title="Assegnazioni">
            <table style="width: 90%; margin: auto">
                <tr v-for="item in assignments">
                    <td class="badge badge-primary text-wrap">{{ item.rwcompany.label }}</td>
                    <td v-if="item.state === 'assigned'" class="text-right"><div class="badge badge-primary text-wrap">{{ item.user.first_name + ' ' + item.user.last_name  }}</div></td>
                    <td v-else-if="item.state === 'unassigned'" class="text-right"><div class="badge badge-secondary text-wrap">non assegnato</div></td>
                    <td v-else-if="item.state === 'sent'" class="text-right"><div class="badge badge-info text-wrap">inviato</div></td>
                    <td v-else-if="item.state === 'queued'" class="text-right"><div class="badge badge-warning text-wrap">accodato</div></td>
                    <td v-else-if="item.state === 'active'" class="text-right"><div class="badge badge-warning text-wrap">invio in corso</div></td>
                </tr>
            </table>
        </b-tab>
        <b-tab title="Log di invio">
            <b-list-group>
                <b-list-group-item href="#" class="flex-column align-items-start" v-for="group in log" :key="group.id" v-b-toggle="'collapse' + group.id">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">{{ group.title }}</h6>
                        <div><b-badge :variant="group.has_error ? 'danger' : 'success'"><span>{{ group.counter }}</span></b-badge></div>
                    </div>
                    <b-collapse :id="'collapse' + group.id" class="mt-2">
                        <b-list-group>
                            <b-list-group-item :variant="item.has_error ? 'danger' : ''" v-for="item in group.items" :key="item.id">
                                <span style="font-size: 0.9rem">{{ item.title }}</span>
                                <p v-if="item.has_error" class="mb-1" style="margin-top: 10px; font-weight: 800;">
                                    {{ item.error }}
                                </p>
                            </b-list-group-item>
                        </b-list-group>
                    </b-collapse>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</template>

<style>
</style>
