//////////////////////////////

module.exports = (type) => {
    var schema = {
        date: {
            type: type.DATEONLY,
            allowNull: false,
        },
        rwcompany_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'rwcompanies',
                key         : 'id',
                constraints : true,
                store_name  : 'rwcompany',
            }
        },
        user_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        state: {
            type: type.STRING(64),
            allowNull: false,
            defaultValue: 'unassigned',
        },
        config: {
            type: type.JSON,
            allowNull: false,
            defaultValue: {},
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

