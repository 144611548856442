<script>

export default {

    name: 'extraction-counter',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        report() { return this.item.report; },
        sum() {
            var sum = 0;
            if ( !this.report || Object.keys( this.report ) === 0 ) { return sum; }
            Object.keys( this.report ).map( customer_id => {
                sum += this.report[ customer_id ].matches.length;
            });
            return sum;
        },
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <b-badge>{{ sum }}</b-badge>
    </div>

</template>


