<script>

const { listpage } = require('@/vue-model-mirror');
const schemas      = require('@/schemaconfig');

export default {
    name: 'extraction-list',
    extends: listpage,
    watch: {
        page( new_val, old_val ) {
            console.log( 'old', old_val );
            console.log( 'new', new_val );
        },
        hash( new_val, old_val ) {
            if ( old_val ) {
                console.log('reload');
                if ( this.$refs.hasOwnProperty('listbnv') ) {
                     //this.$refs.listbnv.page = 1;
                     //console.log(this.$refs.listbnv.get_qry_filters());
                     //console.log( this.page );
                     var options = this.$refs.listbnv.get_qry_filters();
                     //options.page = this.page;
                     this.$refs.listbnv.load_rows( { options } );
                }
            }
        },
    },
    computed: {
        hash() { return this.$store.state.extraction.hash },
        page() { return this.$store.state.extraction.page }
    },
    methods: {
        check_string() {
            try {
                var options = this.$refs.listbnv.get_qry_filters();
            } catch ( error ) { return; }
            //console.log( options );
            this.$store.dispatch('extraction/check_string', { options });
            setTimeout( () => {
                this.check_string();
            }, 5000 );
        },
        reset_modal_add() {},
        save_modal_add_data: function(payload) { // https://www.youtube.com/watch?v=bDqgDWzKX-U
            var match = payload.link.match( /^https:\/\/www\.youtube\.com\/watch\?v=.{11}$/ );
            if ( !match ) { this.url_invalid = true; return; }
            else { this.url_invalid = false; }
            this.$store.dispatch('extraction/add_youtube_link', payload);
            this.toggle_modal();
        },
        submit_add_form() {
            this.$refs.modal_add.submit_form();
        },
        toggle_modal(modal_id = 'modal-add-link-yt') {
            modal_id = modal_id.constructor.name === 'MouseEvent' ? 'modal-add-link-yt' : modal_id;
            this.url_invalid = false;
            this.$root.$emit('bv::toggle::modal', modal_id);
        },
    },
    created() {
        setTimeout( () => {
            this.check_string()
        }, 5000 );
    },
    data () {
        return {
            key_add_link_yt : 0,
            add_schema      : schemas.add_youtube_link,
            url_invalid     : false,
        }
    }
};

</script>

<template>

    <div style="width: 100%; margin: auto">
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "search_schema"
            :name      = "search_schema"
            :schema    = "schema"
            :options   = "form_config"
            :record    = "record"
            :submit    = "false"
            ref        = "search_form"
            :key       = "form_reload"
            :inline    = "inline"
            @reset     = "reset_form"
            @submitted = "operation_success"
        ></bnvform>
        <b-row style="margin-bottom: 10px">
            <b-col v-if="add" align-self="end" class="text-right">
                <!-- <b-button @click="new_element();" size="sm" variant="outline-info"><icon name="plus"/></b-button> -->
                <b-dropdown variant="outline-info" right size="sm" no-caret>
                    <template #button-content>
                        <icon name="plus"/>
                    </template>
                    <b-dropdown-item href="#" @click="toggle_modal()"><icon name="link" style="margin-right: 5px;"/> Link YouTube</b-dropdown-item>
                    <b-dropdown-item href="#" @click="new_element();"><icon name="upload" style="margin-right: 5px;"/> Carica Video</b-dropdown-item>
                </b-dropdown>
            </b-col>
        </b-row>
        <bnvlist
            :schema          = "schema_name"
            storename        = "bnvform"
            :skip            = "skip"
            :fields          = "fields"
            :primarykey      = "primary_key"
            :qryoptions      = "qry_options"
            :details         = "details"
            :detailsclass    = "details_class"
            :detailsaction   = "details_action"
            :edit            = "edit"
            :editaction      = "edit_action"
            :paginate        = "paginate"
            :link            = "link"
            :rowclass        = "rowclass"
            :linkfunction    = "linkfunction"
            :initialfilter   = "initialfilter"
            ref              = "listbnv"
            :size            = "size"
            :formpath        = "item_form"
            @row_selected    = "row_selected"
            @right-click     = "right_click"
        ></bnvlist>

        <!-- modal nuova pratica -->
        <b-modal
            id          = "modal-add-link-yt"
            title       = "Link YouTube"
            @hide       = "reset_modal_add"
            button-size = "sm"
            >
            <bnvform
                :key       = "key_add_link_yt"
                name       = "add_youtube_link"
                :schema    = "add_schema"
                :options   = "{}"
                :record    = "{}"
                :submit    = "false"
                :inline    = "true"
                layout     = "single"
                ref        = "modal_add"
                @submitted = "save_modal_add_data"
            ></bnvform>
            <div style="color: red;" v-if="url_invalid">il link non è valido</div>
            <template v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_add_form">
                      Salva
                    </b-button>
                <!-- </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button> -->
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </div>

</template>



<style>
.extraction-link {
    color: #383838;
    font-weight: 600;
} 
.extraction-link:hover {
    font-weight: 800;
    text-decoration: none;
} 
</style>
