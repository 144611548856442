<script>

export default {

    name: 'extraction-legend',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        report() { return this.item.report; }
    },
    methods: {
        go_to_page( company_id ) {
            this.$store.dispatch('extraction/set_params', { company_id });
            this.$router.push(`/page/extraction/${ this.item.id }`);
        },
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <p style="line-height: 16px; margin-bottom: 2px;" v-if="item.status === 'success'">
            <table style="width: 100%; margin-bottom: 10px;">
                <tr v-for="company_id in Object.keys( report )" :key="company_id">
                    <template v-if="report[ company_id ].matches.length > 0" >
                    <td><small style="font-weight: 600">{{ report[ company_id ].label }}</small></td>
                    <td class="text-center">
                        <b-badge @click="go_to_page(company_id)"  variant="info" v-if="report[ company_id ].matches.length > 0" style="cursor: pointer">{{ report[ company_id ].matches.length }}</a></b-badge>
                        <b-badge variant="secondary" v-if="report[ company_id ].matches.length === 0">{{ report[ company_id ].matches.length }}</a></b-badge>
                    </td>
                    <!-- <td v-else-if="report[ company_id ].matches.length === 0"><small>0</small></td> -->
                    </template>
                </tr>
            </table>
        </p>
    </div>

</template>


