//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(1024),
            allowNull: false,
            unique: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        active: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: true,
        },
        config: {
            type: type.JSON,
            allowNull: false,
            defaultValue: {},
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}

