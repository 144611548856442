<script>

export default {

    name: 'keyword-label',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <a :href="item.url" target="_blank">
            <icon name="link"/>
        </a>
    </div>

</template>


