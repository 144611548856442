<script>

const nprogress = require('nprogress');
const moment = require('moment')

const schemas = require('@/schemaconfig');
import websocket_logger from '../layout/components/websocket_logger'
import toolbar from '@/rwreport/toolbar';
import cardlist from '@/rwreport/card_list';
import cardheader from '@/rwreport/card_header';
import tabs from '@/rwreport/tabs';

export default {
    name: 'rw-index',
    components: {
        toolbar,
        cardlist,
        cardheader,
        tabs,
    },
    mounted () {
        this.$nextTick(function () {
            nprogress.done(true);
        })
    },
    watch: {
        date_filter( new_value, old_value ) {
            //this.$store.dispatch( `${ this.schema_name }/get_all`, { options: { date: this.date_filter, include: true } } );
        },
        loading_customer( new_value, old_value ) {
            if ( new_value === false && old_value === true ) { this.loading_toolbar = false; }
        },
        reset_assignment( new_value, old_value ) {
            if ( old_value === null ) {
                this.$store.dispatch('rwassignment/reset_assignment', new_value );
            }
        },
        free_involved_association( new_value, old_value ) {
            if ( old_value === null ) {
                var [ date, rwcompany_id ] = new_value.split('_');
                this.$store.dispatch('rwassignment/free_involved_association', { date, rwcompany_id } );
            }
        },
    },
    computed: {
        store() { return this.$store.state[ this.schema_name ] },
        items() {
            return this.store.items === null ? [] : this.store.items;
        },
        loading() { return this.store.loading; },
        loading_customer() { return this.$store.state.rwcompany.loading; },
        selected_item() { return this.store.selected_item; },
        sending_loader() { return this.$store.state.websocket_logger.loader },
        page_number() { return this.$store.state.websocket_logger.message.tot },
        reset_assignment() { return this.$store.state.websocket_logger.reset_assignment },
        free_involved_association() { return this.$store.state.websocket_logger.free_involved_association },
    },
    methods: {
        update_values( payload ) {
            if ( this.date_filter !== payload.date || this.company_filter !== payload.company ) {
                this.date_filter = payload.date;
                this.company_filter = payload.company;
                this.$store.dispatch( `${ this.schema_name }/get_all`, { options: { date: payload.date, rwcompany_id: payload.company, include: true }, force_reload: true } );
            }
            this.read_only = payload.read_only;
        },
    },
    created() {
        //if ( this.date_filter === null ) { this.date_filter = moment().format('YYYY-MM-DD') };
        //if ( this.date_filter === null ) { this.date_filter = '2021-05-27' };
        this.$store.dispatch( `rwcompany/get_all` );
        this.$store.dispatch( 'websocket_logger/set_path', 'rw/' );
    },
    data () {
        return {
            loading_toolbar : true,
            schema_name     : 'rwreport',
            date_filter     : null,
            company_filter  : null,
            read_only       : true,
        };
    },
};

</script>

<template>

    <div>
        <b-col>
            <div style="margin-bottom: 20px">
                <toolbar
                    v-if="!loading_toolbar"
                    @update-values="update_values"
                />
            </div>
            <div v-if="loading">
                <div class="text-center">
                    <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="text-center" style="margin-top: 150px;" v-if="!loading && items.length === 0">
                <icon style="color: grey; width: 20%; height: auto;" name="exclamation-triangle"/>
                <p style="color: grey; margin-top: 10px; font-weight: 700;">no results found</p>
            </div>
            <b-row class="" v-if="!loading && items.length > 0">
                <!--  colonna sinistra -->
                <b-col cols="3" style="overflow-y: auto; max-height: 83vh;">
                    <template v-for="item in items">
                        <cardlist :item="item" :read_only="read_only"></cardlist>
                    </template>
                </b-col>
                <!--  colonna destra -->
                <b-col cols="9"  style="overflow-y: auto; max-height: 83vh;">
                    <div v-if="selected_item" style="padding: 5px;">
                        <cardheader :item="selected_item" :read_only="read_only"></cardheader>
                        <div style="margin-top: 20px;">
                            <tabs :item="selected_item" :read_only="read_only"></tabs>
                        </div>
                    </div>
                    <div v-if="!selected_item" class="text-center">
                        <!-- seleziona una pagina web dalla colonna di sinistra -->
                    </div>
                </b-col>
            </b-row>
        </b-col>
        <b-alert
            v-if="sending_loader"
            class="position-fixed fixed-bottom m-0 rounded-0"
            style="z-index: 2000;"
            variant="info"
            dismissible
            show
            >
            <span>coda di invio: {{ page_number }} pagine da inviare</span>
            <b-spinner variant="primary" label="Spinning" style="float: right;" small></b-spinner>
        </b-alert>
    </div>

</template>

