//////////////////////////////

module.exports = (type) => {
    var schema = {
        rassegna: {
            type: type.STRING(64),
            allowNull: false,
            defaultValue: [],
        },
        url: {
            type: type.STRING(1024),
            allowNull: false,
        },
        tipo_rassegna: {
            type: type.STRING(64),
            allowNull: false,
            defaultValue: [],
        },
        file: { // disabled
            type: type.STRING(1024),
            allowNull: true,
        },
        testata: {
            type: type.STRING(1024),
            allowNull: false,
        },
        sezione: {
            type: type.STRING(64),
            allowNull: false,
            defaultValue: [],
        },
        data_notizia: {
            type: type.DATEONLY,
            allowNull: false,
        },
        data_rassegna: {
            type: type.DATEONLY,
            allowNull: false,
        },
        titolo: {
            type: type.STRING(1024),
            allowNull: false,
        },
    };

    return schema;
}

