//////////////////////////////

// questo schema è utilizzato dal frontend
// per generare la maschera di ricerca delle keywords

//////////////////////////////

module.exports = (type) => {
    var schema = {

        label: {
            type: type.STRING(2048),
            allowNull: true,
        },
        company_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'companies',
                key         : 'id',
                constraints : true,
                store_name  : 'company',
            }
        },

    };

    return schema;
}



