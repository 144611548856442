<script>

import bnvbase from './bnvbase.vue';
import Vue     from 'vue';
Vue.use( require('vue-filter-pretty-bytes') );


export default {
    extends: bnvbase,
    props: [ 'maxsize', 'mimetypes', ],
    computed: {
        _model () {
            return ( typeof this.model === 'object' && this.model.hasOwnProperty('original_name')  ) ? this.model.original_name : null;
        },
        check_filter () {
            var res = '*/*';
            if ( this.filter === 'images' ) { res = 'image/*'; }
            return res;
        },
    },
    methods: {
        _validate: function() {
            var check = this._validate_allow_null();
            if ( check === true && this.model === null ) { return true; }
            if ( check === true ) { check = this._validate_maxsize(); }
            if ( check === true ) { check = this._validate_mime_type(); }
            return check;
        },
        _validate_maxsize() {
            var maxsize = 1500000; // default max_size
            if ( this.maxsize && parseInt( this.maxsize ) !== 'NaN' ) { maxsize = parseInt(this.maxsize); }
            if ( this.model.size > maxsize ) {
                this.error_message = `il file pesa ${this.$options.filters.prettyBytes( this.model.size )} (max: ${this.$options.filters.prettyBytes( maxsize )})`;
                this.error_component = true;
                return false;
            }
            return true;
        },
        _validate_mime_type() {
            if ( this._validate_allow_null() === true ) { // TODO devo rifare questo controllo, la funz è da riveder
                var mimetypes = []; // default mime_types (*)
                if ( this.mimetypes && this.mimetypes.hasOwnProperty('length') ) { mimetypes = this.mimetypes; }
                var valid = true;
                for ( var i = 0; i < this.model.length; i++ ) {
                    var file = this.model[ i ];
                    if ( mimetypes.length === 1 && mimetypes[0].match('\/\*$') ) {
                        var tokens = mimetypes[0].split('/');
                        console.log( tokens );
                        if ( file.type.startsWith( tokens[0] ) === false ) {
                            this.error_message = `la tipologia di file '${file.type}' non tra quelli accettati ('${ mimetypes.join('\', \'') }')`;
                            this.error_component = true;
                            return false;
                        }
                    } else if ( mimetypes.length > 0 && mimetypes.indexOf( this.model.type ) === -1 ) {
                        this.error_message = `la tipologia di file '${file.type}' non tra quelli accettati ('${ mimetypes.join('\', \'') }')`;
                        this.error_component = true;
                        return false;
                    }
                }
                return valid;
            }
            return true;
        },
        set_file() { // chiamata internamente al variare di input
            this.model = this.$refs[ 'bnv-form-' + this.name ].files;
            console.log( this.model );
        },
    },
};

</script>

<template>

    <div class="">
        <label :for="'bnv-form-' + name">{{ get_label() }}</label>
        <input
            :accept           = "check_filter"
            :id               = "'bnv-form-' + name"
            :ref              = "'bnv-form-' + name"
            type              = "file"
            class             = "form-control"
            v-bind:class      = "{ 'is-invalid': error_component }"
            v-on:change       = "set_file()"
            :placeholder      = "get_placeholder()"
            :aria-label       = "name"
            :aria-describedby = "name"
            :disabled         = "disabled"
            :readonly         = "_readonly"
            :multiple         = "true"
        />
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted">{{ helper }}</small>
        <small v-if="_model"  class="form-text text-secondary">
            file attualmente salvato: <b-img v-if="model.mimetype !== 'image/svg+xml'" :src="model.url" thumbnail fluid rounded="circle" width="35" alt="customer logo"></b-img> {{ _model }}
        </small>
        <!-- <div v-if="disabled" style="margin-top: 14px;">
            <b-progress :value="upload_progress" :max="100" show-progress></b-progress>
        </div> -->
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>

