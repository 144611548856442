<script>

export default {

    name: 'extraction-counter',
    props: [ 'item', ],
    data() {
        return {
            states: {
                queue: {
                    label: 'coda di elaborazione',
                    variant: 'secondary',
                },
                queue_download: {
                    label: 'coda di download',
                    variant: 'secondary',
                },
                download_in_progress: {
                    label: 'download in corso',
                    variant: 'warning',
                },
                extracting: {
                    label: 'estrazione',
                    variant: 'warning',
                },
                transcript: {
                    label: 'conversione',
                    variant: 'warning',
                },
                searching: {
                    label: 'ricerca',
                    variant: 'warning',
                },
                success: {
                    label: 'ok',
                    variant: 'success',
                },
                error: {
                    label: 'error',
                    variant: 'danger',
                },
            }
        }
    },
    computed: {
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="" class="text-center">
        <b-badge :variant="states[ item.status ].variant">{{ states[ item.status ].label }}</b-badge>
    </div>

</template>


