//////////////////////////////

module.exports = (type) => {
    var schema = {
        title: {
            type: type.STRING(1024),
            allowNull: false,
        },
        link: {
            type: type.STRING(64),
            allowNull: false,
            helper: 'Incolla il link copiato da YouTube'
        },
    };

    return schema;
}


