////////////////////

import md5 from "crypto-js/md5";
const moment = require('moment');

const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];
import base_module from '@/vue-model-mirror/stores/base_v2.module';
import { authHeader } from '@/helpers';
const axios = require('axios');

var options = {
    model_name    : 'extraction',
    send_alert    : false,
    default_order : 'created DESC',
    page          : 1,
};

var base = new base_module( options );

const state = {
    ...base.state,
    hash       : null,
    filters    : {},
    company_id : null,

    // upload queue
    upload_active     : false,
    queue             : [],
    queue_size        : 0,
    active_file_size  : 0,
    chunk_size        : 30000000, // 15MB
    queue_quota       : null,
    active_file_quota : null,
    chunk_quota       : 0,
    chunks_queue      : null,
    chunks_quantity   : null,
    progress_cache    : {},
    queue_percentage  : 0,
    file_percentage   : 0,
    active_file_name  : null,
};

const actions = {
    ...base.actions,

    add_youtube_link( { commit, state, getters }, data ) {
        var url = `${ config.backend_url }model/${ getters.model_name }/YT`;
        var options = [
            url,
            data,
            { headers: authHeader(), }
        ];
        console.log( options );
        axios.post( ...options )
            .catch( error => {
                // TODO
                console.log( error );
                commit('error', error)
            });
    },

    upload_queue( { commit, state, dispatch } ) {
        if ( state.upload_active === true || state.queue.length === 0 ) { return; }
        commit('set_params', { upload_active: true });
        dispatch( 'upload_file' );
    },

    upload_file( { commit, dispatch, state, getters } ) {
        var file = state.queue[0];
        dispatch('set_params', { active_file_size: file.size, active_file_quota: 0, active_file_name: file.name });
        if ( file.size < state.chunk_size ) {
            dispatch('send_file', file);
            return;
        }
        var chunk_size = state.chunk_size;
        var chunks_quantity = Math.ceil(file.size / chunk_size);
        const chunks_queue = new Array( chunks_quantity ).fill().map( ( _, index ) => index ).reverse();
        commit('set_params', { chunks_queue, chunks_quantity });
        dispatch('send_chunk', { file/*, fileId*/ });
    },

    send_file( { commit, state, getters, dispatch }, file ) {
        var schema = getters.model_name;
        var url = `${config.backend_url}model/${schema}/ws`;
        var payload = {};
        const form = new FormData();
        form.append( 'file', file )
        var headers = authHeader();
        headers['Content-Type'] = 'multipart/form-data';
        var options = [
            url,
            form,
            {
                headers,
                onUploadProgress: function( progress_event ) {
                    const file_percent  = Math.round(progress_event.loaded / file.size * 100 * 100) / 100;
                    const queue_percent = Math.round( (state.queue_quota + progress_event.loaded) * 100 / state.queue_size );
                    commit('set_params', {
                        queue_percentage : queue_percent,
                        file_percentage  : file_percent,
                    });
                }
            }
        ];
        axios.post( ...options )
            .then( () => {
                commit('sum_active_file_quota', file.size);
                commit('sum_queue_quota', file.size);
                const queue_percent = Math.round( state.queue_quota * 100 / state.queue_size );
                    commit('set_params', {
                        queue_percentage : queue_percent,
                        file_percentage  : 100,
                    });
                commit( 'remove_from_queue', file.hash );
                if ( state.queue.length > 0 ) {
                    // reset params
                    commit('set_params', {
                        active_file_size  : 0,
                        active_file_quota : 0,
                        chunk_quota       : 0,
                        chunks_queue      : [],
                        chunks_quantity   : null,
                        progress_cache    : {},
                        file_percentage   : 0,
                        active_file_name  : null,
                    });
                    dispatch( 'upload_file' );
                } else {
                    commit('set_params', { upload_active: false });
                    setTimeout( () => {
                        commit('set_params', {
                            queue_size        : 0,
                            active_file_size  : 0,
                            queue_quota       : 0,
                            active_file_quota : 0,
                            chunk_quota       : 0,
                            chunks_queue      : [],
                            chunks_quantity   : null,
                            progress_cache    : {},
                            queue_percentage  : 0,
                            file_percentage   : 0,
                            active_file_name  : null,
                        });
                    }, 2200);
                }
                return;
            })
            .catch( error => {
                // TODO
                commit('error', error)
            });
    },

    send_chunk( { commit, state, dispatch }, { file } ) {
        var chunks_queue    = state.chunks_queue;
        var chunks_quantity = state.chunks_quantity;
        var chunk_size      = state.chunk_size;
        if ( !chunks_queue.length ) {
            dispatch( 'remove_from_queue', file.hash );
            if ( state.queue.length > 0 ) {
                // reset params
                commit('set_params', {
                    active_file_size  : 0,
                    active_file_quota : 0,
                    chunk_quota       : 0,
                    chunks_queue      : [],
                    chunks_quantity   : null,
                    progress_cache    : {},
                    file_percentage   : 0,
                    active_file_name  : null,
                });
                dispatch( 'upload_file' );
            } else {
                commit('set_params', { upload_active: false/*, queue_percentage: 100*/ });
                setTimeout( () => {
                    commit('set_params', {
                        queue_size        : 0,
                        active_file_size  : 0,
                        queue_quota       : 0,
                        active_file_quota : 0,
                        chunk_quota       : 0,
                        chunks_queue      : [],
                        chunks_quantity   : null,
                        progress_cache    : {},
                        queue_percentage  : 0,
                        file_percentage   : 0,
                        active_file_name  : null,
                    });
                }, 2200);
            }
            return;
        }
        const chunk_id = chunks_queue.pop();
        const begin    = chunk_id * chunk_size;
        const chunk    = file.slice(begin, begin + chunk_size);

        var headers = authHeader();

        var on_progress = function(chunkId, event) {
            if (event.type === "progress" || event.type === "error" || event.type === "abort") {
                commit('set_progress_chunk', { chunk_id, bytes: event.loaded });
            }
        
            if (event.type === "loadend") {
                commit('sum_active_file_quota', state.progress_cache[chunk_id] || 0);
                commit('sum_queue_quota', state.progress_cache[chunk_id] || 0);
                commit('remove_progress_chunk', chunk_id)
            }
            const in_progress = Object.keys(state.progress_cache).reduce((memo, id) => memo + state.progress_cache[id], 0); 
            const sent_length_file  = Math.min(state.active_file_quota + in_progress, file.size);
            const sent_length_queue = Math.min(state.size_quota + in_progress, state.queue.size);
            const file_percent  = Math.round(sent_length_file / file.size * 100 * 100) / 100;
            const queue_percent = Math.round( state.queue_quota * 100 / state.queue_size );
//          console.log( 'active_file_quota :', state.active_file_quota );
//          console.log( 'active_file_size  :', state.active_file_size );
//          console.log( 'file_percentage   :', file_percent );
//          console.log( 'queue_size        :', state.queue_size );
//          console.log( 'queue_quota       :', state.queue_quota );
//          console.log( 'queue_percentage  :', queue_percent );
            commit('set_params', {
                queue_percentage : queue_percent,
                file_percentage  : file_percent,
            });
        }
        var upload_chunk = function( chunk, chunk_id, chunks_quantity, file, auth) {
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();

                const progress_listener = on_progress.bind(null, chunk_id);
                xhr.upload.addEventListener("progress", progress_listener);
                xhr.addEventListener("error", progress_listener);
                xhr.addEventListener("abort", progress_listener);
                xhr.addEventListener("loadend", progress_listener);

                var url = `${config.backend_url}model/${ options.model_name }`;
                xhr.open("post", url);
        
                xhr.setRequestHeader("Content-Type", "application/octet-stream");
                xhr.setRequestHeader("X-Chunk-Id", chunk_id);
                xhr.setRequestHeader("X-Chunks-Quantity", chunks_quantity);
                xhr.setRequestHeader("X-Content-Id", file.hash);
                xhr.setRequestHeader("Authorization", auth);
                xhr.setRequestHeader("X-Content-Length", file.size);
                xhr.setRequestHeader("X-Content-Name", file.name);
        
                xhr.onreadystatechange = () => {
                    if ( xhr.readyState === 4 && [ 200, 201 ].includes( xhr.status ) ) {
                        resolve();
                    }
                };
                xhr.onerror = reject;
        
                xhr.send(chunk);
            });
        }

        upload_chunk( chunk, chunk_id, chunks_quantity, file, headers['Authorization'])
            .then(() => {
                dispatch('send_chunk', { file });
            })
            .catch(() => {
                chunks_queue.push(chunk_id);
            });
    },

    get_all( { commit, state, getters }, _data = { options: {} } ) {
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        console.log( data.options );
        data.options.order = getters.order;
        commit('start_request');
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                var string = JSON.stringify( response );
                var md5_string = md5( string ).toString();
                commit('set_params', { hash: md5_string });
                if ( data.options.paginate === true ) { // gestione paginator
                    commit('success_list', response.data);
                    commit('set_total', response.tot);
                    console.log('response.page', response.page);
                    commit('set_page', response.page);
                    commit('set_params', { page: response.page });
                    commit('set_rows_per_page', response.rows_per_page);
                } else {
                    commit('success_list', response)
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    check_string( { commit, state, getters }, _data = { options: {} } ) {
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        data.options.order = getters.order;
        state.service.get_all(getters.model_name, data.options)
            .then( response => {
                var string = JSON.stringify( response );
                var md5_string = md5( string ).toString();
                if ( state.hash !== md5_string ) {
                    //console.log( 'MD5 VARIATO' );
                    //console.log( 'old:', state.hash );
                    //console.log( 'new:', md5_string );
                    commit('set_params', { hash: md5_string });
                }
            })
            .catch( error => {
                commit('error', error)
            });
    },
    push_to_queue( { commit }, _files ) {
        var files = _files;
        if ( _files.constructor.name === 'File' ) {
            files = [ _files ];
        }
        commit('push_to_queue', files);
    },
    remove_from_queue( { commit }, hash ) { commit('remove_from_queue', hash); },
};

const mutations = {
    ...base.mutations,
    push_to_queue( state, files ) {
        for ( var i = 0; i < files.length; i++ ) {
            var file = files[ i ];
            var hash_obj = {
                name         : file.hash,
                lastModified : file.lastModified,
                size         : file.size,
                type         : file.type
            };
            var hash = md5( JSON.stringify( hash_obj ) ).toString();
            if ( file.constructor.name !== 'File' ) {
                console.warning( '====================' );
                console.warning('');
                console.warning( 'la coda di upload contiene acccedetta solo objects di tipo File. Trovato:' ); 
                console.warning( file )
                console.warning( 'skip.' );
                console.warning('');
                console.warning( '====================' );
                continue;
            }
            if ( state.queue.map( x => x.hash ).includes( hash ) ) {
                console.warning( '====================' );
                console.warning('');
                console.warning( 'Il file è già in coda' ); 
                console.warning( 'skip.' );
                console.warning('');
                console.warning( '====================' );
                continue;
            }
            file.hash = hash;
            state.queue.push( file );
            state.queue_size += file.size;
        }
        console.log( state.queue );
        console.log( state.queue_size );
    },
    remove_from_queue( state, hash ) {
        var index = null;
        var files = state.queue;
        for ( var i = 0; i < files.length; i++ ) {
            if ( files[ i ].hash === hash ) { var index = i; break; }
        }
        if ( index !== null ) {
            state.queue.splice(index, 1);
        }
    },
    set_progress_chunk( state, obj ) { // mutations
        if ( typeof obj === 'object' && obj.hasOwnProperty('chunk_id') && obj.hasOwnProperty('bytes') ) {
            state.progress_cache[ obj.chunk_id ] = obj.bytes;
        }
    },
    sum_chunk_quota( state, quota ) {
        state.chunk_quota += quota;
    },
    remove_progress_chunk( state, chunk_id ) {
        delete state.progress_cache[ chunk_id ];
    },
    sum_active_file_quota( state, quota ) {
        state.active_file_quota += quota;
    },
    sum_queue_quota( state, quota ) {
        state.queue_quota += quota;
    },
};

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
};

