<script>

export default {

    name: 'extraction-error',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        report() { return this.item.report; },
        has_error() { return this.item.msg ? true : false; }
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <span v-if="has_error" style="background-color: red; color: white; font-weight: 700;">{{ this.item.msg }}</span>
        <span v-else-if="has_error === false" style="color: grey; font-eright: 600;">no error detected</span>
    </div>

</template>


