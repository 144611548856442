<script>

export default {

    name: 'rwkeyword-label',
    props: [ 'item', ],
    data() {
        return {
            attr_name : null,
            label     : null,
            telefono  : null,
            cellulare : null,
            email     : null,
            indirizzo : null,
        }
    },
    computed: {},
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <div style="">
        <b-badge style="font-size: 11pt;" variant="info">{{ item.label }}</b-badge>
    </div>

</template>


