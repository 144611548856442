//////////////////////////////////////////////////

const cmps = require('./list_components');

module.exports = {

    site: {
        title_list    : 'SITI',
        order         : 'label DESC',
        add           : false,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome', sortable: true, screen: 'mobile' },
            { key: 'link', label: 'Link', component: cmps.site_link, sortable: false, screen: 'mobile' },
            { key: 'customer.label', label: 'Customer', sortable: true, screen: 'mobile' },
            { key: 'active', label: 'Attivo', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
        ],
    },

    channel: {
        title_list    : 'CANALI YOUTUBE',
        order         : 'label DESC',
        add           : true,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome', sortable: true, screen: 'mobile' },
            { key: 'customer.label', label: 'Customer', sortable: true, screen: 'mobile' },
            { key: 'active', label: 'Attivo', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
        ],
    },

    extraction: {
        title_list    : 'ESTRAZIONI',
        order         : 'created DESC',
        add           : true,
        edit          : false,
        details       : true,
        include       : true,
        paginate      : true,
        initialfilter : {},
        link          : 'link',
        //search_schema : 'search_statistic',
        fields: [
            { key: 'title', label: 'File', component: cmps.extraction_title, sortable: false, screen: 'mobile', class: [ 'align-middle'] },
            { key: 'user', label: 'Utente', component: cmps.extraction_user, sortable: false, screen: 'desktop', class: [ 'align-middle'] },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Data', screen: 'desktop' },
            { key: 'status', sortable: true, label: 'Stato', component: cmps.extraction_status, screen: 'mobile', class: [ 'align-middle'] },
            { key: 'counter', label: 'Esito', component: cmps.extraction_counter, sortable: true, screen: 'mobile', class: [ 'align-middle'] },
            { key: 'download', sortable: false, label: '', component: cmps.extraction_download, screen: 'mobile', class: [ 'align-middle'] },
            { key: 'legend', label: 'Occorrenze', component: cmps.extraction_legend, sortable: false, display: false },
            { key: 'error', label: 'Errors', component: cmps.extraction_error, sortable: false, display: false },
        ],
    },

    customer: {
        title_list    : 'CUSTOMERS',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome', sortable: true, screen: 'mobile' },
            { key: 'active', label: 'Attivo', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
            { key: 'user.api.label', sortable: false, label: 'Creato da', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Creato il', screen: 'mobile' },
        ],
    },

    company: {
        title_list    : 'CLIENTI RASSEGNA VIDEO',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome Cliente', sortable: true, screen: 'mobile' },
            { key: 'active', label: 'Attivo', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
            { key: 'user.api.label', sortable: false, label: 'Creato da', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Creato il', screen: 'mobile' },
        ],
    },

    rwcompany: {
        title_list    : 'CLIENTI RASSEGNA WEB',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        details       : false,
        include       : true,
        paginate      : true,
        initialfilter : {},
        fields: [
            { key: 'label', label: 'Nome Cliente', sortable: true, screen: 'mobile' },
            { key: 'active', label: 'Attivo', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
            { key: 'user.api.label', sortable: false, label: 'Creato da', screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Creato il', screen: 'mobile' },
        ],
    },

    keyword: {
        title_list    : 'PAROLE CHIAVE RASSEGNA VIDEO',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        details       : true,
        details_class : '', // sparisce su desktop
        include       : true,
        paginate      : true,
        initialfilter : {},
        search_schema : 'search_keyword',
        fields: [
            { key: 'label', label: 'Parola Chiave', component: cmps.keyword_label, sortable: true, screen: 'mobile' },
            { key: 'company.label', label: 'Cliente', sortable: true, screen: 'mobile' },
            { key: 'enabled', label: 'Abilitato', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
            { key: 'user.label', label: 'Creato da', sortable: true, screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Creato il', screen: 'mobile' },
            { key: 'delete', label: 'Azioni', component: cmps.keyword_delete, display: false },
        ],
    },

    rwkeyword: {
        title_list    : 'PAROLE CHIAVE RASSEGNA WEB',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        details       : true,
        details_class : '', // sparisce su desktop
        include       : true,
        paginate      : true,
        initialfilter : {},
        search_schema : 'search_rwkeyword',
        fields: [
            { key: 'label', label: 'Parola Chiave', component: cmps.rwkeyword_label, sortable: true, screen: 'mobile' },
            { key: 'rwcompany.label', label: 'Cliente', sortable: true, screen: 'mobile' },
            { key: 'enabled', label: 'Abilitato', htmlformatter: 'convert_boolean', sortable: true, screen: 'mobile' },
            { key: 'stype', label: '', component: cmps.rwkeyword_stype, sortable: false, screen: 'mobile' },
            { key: 'sword', label: '', component: cmps.rwkeyword_sword, sortable: false, screen: 'mobile' },
            { key: 'created', htmlformatter: 'format_datetime_t2', sortable: true, label: 'Creato il', screen: 'mobile' },
            { key: 'user.label', label: 'Creato da', sortable: true, display: false },
            { key: 'delete', label: 'Azioni', component: cmps.rwkeyword_delete, display: false },
        ],
    },

}

// *: mettere l'id nell'ordinamento risolve il problema della gestione "read"
//    però genera due problematiche:
//        a) perdo il linking tra ordinamento di default e th della tabella
//        b) posso riordinare la tabella cliccando sul th ma perdo l'ordinamento per id

//    SOLUZIONE: attributo 'order' e attributo 'default_order'

// REFERENCES

// title_list     : titolo della pagina list                               - string
// skip           : campi del model da non trattare                        - string[]
// order          : ordine iniziale della tabella                          - string (es: 'label ASC')
// include        : il backend restituisce i record related                - boolean
// details        : mostra il bottone per i dettagli                       - boolean
// details_class  : classe del bottone                                     - boolean
// edit           : mostra il bottone 'modifica'                           - boolean
// add            : mostra il bottone 'nuovo'                              - boolean
// paginate       : mostra il paginator e abilita la paginazione           - boolean
// primarykey     : attributo degli item da usare come chiave per le row   - string
// link           : attributo degli item da usare come link                - string
// search_schema  : schema per la form di ricerca                          - string
// rowclass       : funzione per l'assegnazione di una classe ad una riga  - function
// linkfunction   : filtro iniziale                                        - object
// fields:
//     key           : nome attributo item                         - string
//     sortable      : abilita l'ordinamento                       - boolean
//     label         : etichetta da mostrare nel th                - string
//     formatter     : funzione per la normalizzazione del dato    - string
//     htmlformatter : funzione per la normalizzazione del dato    - string
//                     che restituisce una stringa html
//     display       : se uguale a false non mostra la colonna     - boolean
//     stickyColumn  : fissa la colonna nello scorrimento orizz.   - boolean
//     class         : classe della cella/colonna                  - string[] ( [ 'classe usata sempre', 'classe della colonna', 'classe del detail' ] ) oppure
//                                                                   string   ( in questo caso la classe verrà usata sempre )
//     screen        : size dal quale far comparire il campo       - string ( 'mobile' | 'tablet' | 'desktop' )

