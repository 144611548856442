////////////////////

const axios = require('axios');
import base_service from '../services/base.service';
import { authHeader } from '@/helpers';
const config = require('@/config.js')[ process.env.NODE_ENV || 'local' ];

export default class {

    constructor ( options, service = null ) {
        var confobj = new class_config( options );
        this.state = {
            ...base.state,
            options : confobj.options,
            service : service === null ? base_service : service,
        };

        this.getters = base.getters,

        this.actions = base.actions;

        this.mutations = base.mutations;

    }

}

class class_config { // override default_options with user-defined-options

    constructor( user_options = null ) {
        this.default_options = {
            send_alert     : true,
            form_title     : null,
            page           : 1,
            rows_per_page  : 25,
            default_order  : 'id DESC',
            model_name     : null,
        };
        this.user_options = user_options === null ? {} : user_options;
    }

    get options() {
        var keys = Object.keys( this.user_options );
        if ( keys.length === 0 ) { return this.default_options; }
        var res = {};
        keys.map( key => {
            res[ key ] = this.user_options.hasOwnProperty( key ) === true ? this.user_options[ key ] : this.default_options[ key ];
        });
        return res;
    }

}

const base = {
//  namespaced: true,
    state: {
        obj               : {},      // ok
        items             : null,    // ok
        loading           : false,   // ok
        loading_by_id     : false,
        error             : false,   // ok
        executed          : false,
        created           : false,
        updated           : false,
        force_reload      : false,   // ok
        filters           : {},
        order             : null,    // ok
        tot               : 0,       // ok
        selected          : false,
        upload_percentage : 0,
        map               : new Map(),
    },
    getters: {
        form_title: state => {
            return state.options.form_title === null ? `${ options.model_name.toUpperCase() } FORM` : state.options.title;
        },
        order: state => {
            return state.order === null ? state.options.default_order : state.order;
        },
        page: state => state.options.page,
        rows_per_page: state => state.options.rows_per_page,
        model_name: state => state.options.model_name,
    },
    actions: {
        get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
            var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
            commit('start_request');
            data.options.order = getters.order;
            Object.keys( state.filters ).map( key => { data.options[ key ] = state.filters[ key ]; } );
            if ( state.items === null || state.force_reload === true || ( data.hasOwnProperty('force_reload') && data.force_reload === true ) ) {
                state.service.get_all(getters.model_name, data.options)
                    .then( response => {
                        if ( data.options.paginate === true ) { // gestione paginator
                            commit('success_list', response.data);
                            commit('set_total', response.tot);
                            commit('set_page', response.page);
                            commit('set_rows_per_page', response.rows_per_page);
                        } else {
                            commit('success_list', response)
                        }
                    })
                    .catch( error => {
                        commit('error', error)
                    });
            } else {
                commit('stop_request');
            }
        },
        get_by_id({commit, state, getters}, data) {
            commit('start_request_id');
            state.service.get_by_id(getters.model_name, data.id, data.include)
                .then( rows => {
                    commit('success_obj', rows);
                    commit('success_select');
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        create({ commit, state, getters }, data) {
            commit('start_request');
            state.service.create( getters.model_name, data.payload, data.include )
                .then( obj => {
                    commit('success', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
//      createws({ commit, state, getters }, data) {
//          commit('start_request');
//          state.service.createws( getters.model_name, data.payload, data.include )
//              .then( obj => {
//                  commit('success', obj);
//              })
//              .catch( error => {
//                  commit('error', error)
//              });
//      },
        update({ commit, state, getters }, data) {
            commit('start_request');
            state.service.update( getters.model_name, data.id, data.payload, data.include )
                .then( obj => {
                    commit('success', obj);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        createws( { commit, state, getters }, data ) {
            commit('start_request');
            var schema = getters.model_name;
            var url = `${config.backend_url}model/${schema}/ws`;
            console.log( schema, data, url );
            if ( data.include ) { url += `?include=true` }
            var payload = {};
            const form = new FormData();
            Object.keys( data.payload ).map( x => {
                form.append(x, data.payload[ x ]);
            });
            var headers = authHeader();
            headers['Content-Type'] = 'multipart/form-data';
            var options = [
                url,
                form,
                {
                    headers,
                    onUploadProgress: function( progress_event ) {
                        var upload_percentage = parseInt( Math.round( ( progress_event.loaded / progress_event.total ) * 100 ) );
                        commit('set_percentage', upload_percentage );
                    }
                }
            ];
            axios.post( ...options )
                .then( obj => {
                    setTimeout( () => {
                        commit('success', obj);
                    }, 750);
                })
                .catch( error => {
                    commit('error', error)
                });
        },
        force_reload({commit}) { commit('force_reload'); },
        executed({commit}) { commit('executed'); },
        created({commit}) { commit('created'); },
        clear({commit}) { commit('clear'); },
        set_filters( {commit}, filters ) { commit('setfilters', filters); },
        add_filter( {commit}, filter ) { commit('addfilter', filter); },
        remove_filter( {commit}, index ) { commit('removefilter', index); },
        set_order( {commit}, value ) { commit('set_order', value); },
        set_params( { commit }, obj ) { commit('set_params', obj); }
    },
    mutations: {
        set_order ( state, value ) { state.order = value; }, // ok
        setfilters( state, filters ) { state.filters = filters; },
        addfilter( state, filter ) {
            var index = Object.keys( filter )[0];
            state.filters[ index ] = filter[ index ];
        },
        removefilter( state, index ) { delete state.filters[ index ]; },
        start_request_id( state ) {
            state.loading_by_id = true;
            state.error         = false;
            state.executed      = false;
            state.force_reload  = false;
        },
        start_request( state ) {
            state.loading      = true;
            state.error        = false;
            state.executed     = false;
            state.force_reload = false;
        },
        stop_request( state ) {
            state.loading      = false;
            state.force_reload = false;
        },
        success( state, obj ) {
            state.obj           = obj;
            state.loading       = false;
            state.loading_by_id = false;
            state.error         = false;
            state.executed      = true;
        },
        successcreate( state, obj ) {
            state.obj      = obj;
            state.loading  = false;
            state.loading_by_id = false;
            state.error    = false;
            state.executed = false;
            state.created  = true;
        },
        success_obj( state, obj ) {
            state.obj      = obj;
            state.loading  = false;
            state.loading_by_id = false;
            state.error    = false;
            state.executed = false;
        },
        success_select( state ) {
            state.selected = true;
        },
        success_list( state, items ) {
            state.items    = items;
            items.map( x => {
                state.map.set( x.id, x );
            });
            state.loading  = false;
            state.loading_by_id = false;
            state.error    = false;
            state.executed = true;
        },
        error( state, error ) {
            state.obj          = error;
            state.loading      = false;
            state.error        = true;
            state.executed     = false;
            state.force_reload = true;
        },
        set_total( state, data ) { state.tot = data; },
        set_page( state, data ) { state.page = data; },
        set_rows_per_page( state, data ) { state.rows_per_page = data; },
        force_reload(state) { state.force_reload = true; },
        executed(state) {
            state.executed = false;
            state.selected = false;
        },
        created(state) { state.created = false; },
        clear( state ) {
            state.obj           = {};
            state.items         = null;
            state.loading       = false;
            state.loading_by_id = false;
            state.error         = false;
            state.executed      = false;
            state.force_reload  = false;
            state.selected      = false;
        },
       set_params( state, obj ) {
           if ( typeof obj === 'object' ) {
               var keys = Object.keys( obj );
               for ( var i = 0; i < keys.length; i++  ) {
                   if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
               }
           }
       },
       set_percentage( state, value ) {
           state.upload_percentage = value;
           if ( parseInt( value ) === 100 ) {
               setTimeout( () => {
                   state.upload_percentage = 0;
               }, 1000);
           }
       }
    }
}


