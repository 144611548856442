<script>

export default {

    name: 'rwkeyword-wtype',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        case_insensitive() { return this.item.config.case_insensitive; },
        exact_search() { return this.item.config.exact_search; },
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <b-col style="">
        <b-row :style="{ 'color': case_insensitive ? 'black' : '#717171', 'opacity': case_insensitive ? '1' : '0.7' }">
            <div style="width: 20px;"><icon style="" :name="case_insensitive ? 'check-square' : 'ban'"/></div><div>Ignora maiuscole</div>
        </b-row>
        <b-row :style="{ 'color': exact_search ? 'black' : '#717171', 'opacity': exact_search ? '1' : '0.7' }">
            <div style="width: 20px;"><icon style="" :name="exact_search ? 'check-square' : 'ban'"/></div><div>Ricerca Esatta</div>
        </b-row>
    </b-col>

</template>


