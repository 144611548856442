////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';
import axios from 'axios';

const config = require('../config.js')[ process.env.NODE_ENV ];

var options = {
    model_name    : 'rwcompany',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    toast         : null,
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = [ 'label', ].includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        //if ( state.items === null || state.force_reload === true || ( data.hasOwnProperty('force_reload') && data.force_reload === true ) ) {
            state.service.get_all(getters.model_name, data.options)
                .then( response => {
                    if ( data.options.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        //}
    },
    lock_domain( { commit, getters }, { domain, rwcompany_id } ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ rwcompany_id }/lock_domain`, { domain } )
            .then( response => {
                commit( 'override_item', response.data );
                commit( 'set_toast', { title: 'Aggiornamento dati customer', body: 'Dominio bloccato con successo' } );
            })
            .catch( error => {
                console.log( error );
            });
    },
    unlock_domain( { commit, getters }, { domain, rwcompany_id } ) {
        axios.patch( config.backend_url + `model/${ getters.model_name }/${ rwcompany_id }/unlock_domain`, { domain } )
            .then( response => {
                commit( 'override_item', response.data );
                commit( 'set_toast', { title: 'Aggiornamento dati customer', body: 'Dominio abilitato con successo' } );
            })
            .catch( error => {
                console.log( error );
            });
    },
};

const mutations = {
    ...base.mutations,
    override_item( state, item ) {
        var map = new Map();
        var items = [];
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === item.id ) { items.push( item ); map.set( item.id, item ); continue; }
            items.push( state.items[ i ] );
            map.set( state.items[ i ].id, state.items[ i ] );
        }
        state.items = items;
        state.map   = map;
    },
    set_toast( state, obj ) { 
        state.toast = null;
        state.toast = obj;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};

