<script>

import Vue      from 'vue';
import menu_bar from '@/layout/components/menu_bar';

Vue.component( 'menu-bar', menu_bar );

export default {
    name: 'app',
    computed: {
        is_logged () {
            return ( this.$store.state.authentication.status.hasOwnProperty('loggedIn') && this.$store.state.authentication.status.loggedIn === true ) ? true : false;
        },
        extraction_uploading() { return this.$store.state.extraction.upload_active; },
        file_percentage() { return this.$store.state.extraction.file_percentage; },
        queue_percentage() { return this.$store.state.extraction.queue_percentage; },
        file_name() { return this.$store.state.extraction.active_file_name; },
    },
    watch: {
        extraction_uploading( new_val, old_val ) {
            if ( new_val === true && old_val === false ) {
                this.$bvToast.show('upload-toast');
            } else if ( new_val === false && old_val === true ) {
                setTimeout( () => {
                    this.$bvToast.hide('upload-toast');
                }, 1500);
            }
        },
    },
    components: {},
    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    },
}

</script>

<template>

    <div id="cnt2">
        <menu-bar v-if="is_logged"></menu-bar>
        <b-container style="margin-top: 70px" fluid>
            <router-view :key="$route.fullPath" />
        </b-container>
        <b-toast
            id       = "upload-toast"
            variant  = "dark"
            :solid   = "true"
            toaster  = "b-toaster-bottom-right"
            solid
            no-auto-hide
            no-close-button
            >
            <template #toast-title>
                <div class="d-flex flex-grow-1 align-items-baseline">
                    <b-img blank blank-color="#ff5555" class="mr-2" width="12" height="12"></b-img>
                    <strong class="mr-auto">Upload in corso...</strong>
                    <!-- <small class="text-muted mr-2">42 seconds ago</small> -->
                </div>
            </template>
            <b-progress height="3px" :value="file_percentage" :max="100"></b-progress>
            <small v-if="file_name" style="color: grey;">{{ file_name | truncate(55, '...') }}<b-badge variant="light" style="float: right; margin-top: 4px">{{ Math.round(file_percentage) }}%</b-badge></small>
            <b-progress style="margin-top: 10px;" height="3px" :value="queue_percentage" :max="100"></b-progress>
            <small style="">Progresso Totale<b-badge variant="light" style="float: right; margin-top: 4px;">{{ queue_percentage }}%</b-badge></small>
        </b-toast>
    </div>

</template>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }
</style>
