<script>

const nprogress = require('nprogress');
const moment = require('moment')

const schemas = require('@/schemaconfig');
import bnvform from '@/vue-model-mirror/bnvform';

export default {
    name: 'rw-report-card-header',
    props: [ 'item', 'read_only' ],
    components: {
        bnvform,
    },
    mounted () {
    },
    watch: {
        toast_report( new_value, old_value ) {
            if ( typeof new_value === 'object' ) { this.make_toast( new_value ); }
        },
        toast_company( new_value, old_value ) {
            if ( typeof new_value === 'object' ) { this.make_toast( new_value ); }
        },
    },
    computed: {
        store() { return this.$store.state[ this.schema_name ] },
        selected_id() { return this.store.selected_item.id; },
        is_selected() { return this.selected.item === this.item.id },
        datetime() { return moment( this.item.created, 'YYYY-MM-DD HH:mm:ss.sssZ' ).format('DD/MM/YYYY - HH:mm'); },
        company() { return this.$store.state.rwcompany.map.get( this.item.rwcompany.id ) },
        is_locked() {
            if ( this.company.config.hasOwnProperty('locked_domains') === false ) { return false };
            return this.company.config.locked_domains.includes( this.item.data.domain );
        },
        toast_report() { return this.store.toast; },
        toast_company() { return this.$store.state.rwcompany.toast; },
        state_text() {
            if ( this.item.state === 'sent' ) { return 'la pagina è stata inviata con successo'; }
            if ( this.item.state === 'error' ) { return 'l\'invio della pagina è fallito'; }
            return 'no-content';
        },
        display_header() {
            return this.item.has_error === true || this.item.state !== null || this.item.data.locked !== false;
        },
        header_content() {
            if ( this.display_header === false ) { return 'no-content'; }
            if ( this.item.has_error ) { return this.item.error; }
            if ( this.item.data.locked !== false ) { return 'report non confermato per l\'invio'; }
            return this.state_text;
        },
        header_text_variant() {
            if ( this.display_header === false ) { return undefined; }
            if ( this.item.has_error ) { return 'black'; }
            if ( this.item.state !== null ) { return 'white'; }
        },
        header_variant() {
            if ( this.display_header === false ) { return undefined; }
            if ( this.item.has_error ) { return 'warning'; }
            if ( this.item.state !== null ) { return this.item.state === 'error' ? 'danger' : 'success'; }
        },
        replace_pdf_schema() { return schemas.replace_pdf; },
        upload_active() { return this.store.upload_active },
    },
    methods: {
        set_selected() {
            this.$store.dispatch( `${ this.schema_name }/set_selected`, this.item );
        },
        disable_domain() {
            this.$store.dispatch( 'rwcompany/lock_domain', {
                domain       : this.item.data.domain,
                rwcompany_id : this.company.id
            });
        },
        enable_domain() {
            this.$store.dispatch( 'rwcompany/unlock_domain', {
                domain       : this.item.data.domain,
                rwcompany_id : this.company.id
            });
        },
        exclude() {
            this.$store.dispatch( `${ this.schema_name }/exclude`, this.item.id);
        },
        include() {
            var payload = { id: this.item.id, data_rassegna: this.item.data.orazio.data_rassegna };
            if ( this.item.state === 'sent' ) {
                this.$bvModal.msgBoxConfirm(`Il report risulta già inviato. Confermi l'operazione?`)
                    .then( res => {
                        if ( res === true ) {
                            this.$store.dispatch( `${ this.schema_name }/include`, payload );
                        }
                    })
                    .catch( error => {
                        console.log( error );
                    });
            } else if ( this.item.has_error && this.item.error !== 'Pagina esclusa manualmente' ) {
                this.$bvModal.msgBoxConfirm(`Il report risulta aver incontrato errori durante il download, potrebbe essere sprovvisto di dati obbligatori o del file pdf. Confermi?`)
                    .then( res => {
                        if ( res === true ) {
                            this.$store.dispatch( `${ this.schema_name }/include`, payload );
                        }
                    })
                    .catch( error => {
                        console.log( error );
                    });
            } else {
                this.$store.dispatch( `${ this.schema_name }/include`, payload );
            }
        },
        queue_report() {
            var items = [ this.item.id ];
            this.$bvModal.msgBoxConfirm(`Aggiungere la pagina alla coda di invio?`)
                .then( value => {
                    if ( value === true ) {
                        this.$store.dispatch( 'rwassignment/queue_customer', {
                            reports : [ { id: this.item.id, date: this.item.date, company_id: this.item.rwcompany_id } ],
                            company : this.item.rwcompany_id,
                            date    : this.item.date,
                        });
                    }
                })
                .catch( error => {
                    console.log( error );
                });
        },
        upload_report() {
            this.$root.$emit('bv::toggle::modal', 'modal-pdf');
        },
        submit_pdf( data ) {
            this.$store.dispatch(`${this.schema_name}/replace_pdf`, data);
        },
        submit_pdf_form() {
            this.$refs.replace_pdf_form.submit_form();
        },
        make_toast( obj ) {
            var body = obj.body;
            var title = obj.title;
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 5000,
                appendToast   : true,
                variant       : 'success',
                toaster       : 'b-toaster-bottom-right',
                solid         : true,
            });
        },
    },
    created() {},
    data () {
        return {
            schema_name : 'rwreport',
            modal_pdf   : 0,
        };
    },
};

</script>

<template>

    <b-card
        :header-text-variant = "header_text_variant"
        :header-bg-variant   = "header_variant"
        :border-variant      = "header_variant"
        >
        <template v-if="display_header" #header>
            <div style="text-align: center;">{{ header_content }}</div>
        </template>
        <b-card-title v-if="item.title" style="font-weight: 600; margin-bottom: 20px;"><a :href="item.link" target="_blank">{{ item.title }}</a></b-card-title>
        <b-card-title v-else-if="!item.title" style="font-weight: 500; color: grey; margin-bottom: 20px;"><a :href="item.link" target="_blank">titolo non disponibile</a></b-card-title>
        <b-card-text>
            <b-row style="margin-bottom: 15px">
                <b-col><icon class="icon_legend" name="building"/>{{ company.label }}</b-col>
                <b-col v-if="!item.data.manual"><icon class="icon_legend" name="key"/><b-badge>{{ item.keyword_label }}</b-badge></b-col>
                <b-col v-if="item.data.manual === true"><icon class="icon_legend" name="bookmark"/><b-badge>rassegna manuale</b-badge></b-col>
                <b-col v-if="!item.data.manual"><icon class="icon_legend" name="search"/><a :href="item.data.google_page_url" target="_blank">ricerca</a></b-col>
                <b-col v-if="item.data.manual === true"></b-col>
            </b-row>
            <b-row style="margin-bottom: 30px">
                <b-col><icon class="icon_legend" name="play"/>{{ item.data.domain }}</b-col>
                <b-col><icon class="icon_legend" name="clock"/>{{ datetime }}</b-col>
                <b-col><icon class="icon_legend" name="file"/>{{ item.data.page_count }}</b-col>
            </b-row>

            <b-row style="margin-bottom: 15px" align-h="around">
                <b-button :style="{ 'opacity': read_only || display_header ? 0.3 : 1 }"  size="sm" :disabled="read_only || display_header" @click="exclude" variant="danger"><icon class="icon_legend" name="times-circle"/>Escludi dall'invio</b-button>
                <b-button :style="{ 'opacity': read_only || display_header === false ? 0.3 : 1 }" size="sm" :disabled="read_only || display_header === false" @click="include" variant="info"><icon class="icon_legend" name="check"/>Includi nell'invio</b-button></b-button>
                <b-button :style="{ 'opacity': read_only || is_locked ? 0.3 : 1 }" size="sm" :disabled="read_only || is_locked" @click="disable_domain" variant="danger"><icon class="icon_legend" name="ban"/>Blocca dominio</b-button>
                <b-button :style="{ 'opacity': read_only || !is_locked ? 0.3 : 1 }" size="sm" :disabled="read_only || !is_locked" @click="enable_domain" variant="info"><icon class="icon_legend" name="check"/>Abilita dominio</b-button>
            </b-row>
            <b-row style="" align-h="around">
                <b-button :style="{ 'opacity': read_only || display_header ? 0.3 : 1 }" size="sm" :disabled="read_only || display_header" @click="upload_report" variant="warning"><icon class="icon_legend" name="upload"/>Sostituisci pdf</b-button>
                <b-button :style="{ 'opacity': read_only || display_header ? 0.3 : 1 }" size="sm" :disabled="read_only || display_header" @click="queue_report" variant="success"><icon class="icon_legend" name="arrow-right"/>Invia singolo articolo</b-button>
            </b-row>
            <!-- <div class="text-right">
                <b-dropdown variant="light" right id="actions" text="opzioni" size="sm" class="m-md-2" :disabled="read_only">
                    <b-dropdown-item-button v-if="display_header === false" @click="exclude" variant="danger"><icon class="icon_legend" name="times-circle"/>Escludi dall'invio</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="display_header" @click="include"><icon class="icon_legend" name="check"/>Includi nell'invio</b-button></b-dropdown-item-button>
                    <b-dropdown-item-button v-if="!is_locked" @click="disable_domain" variant="danger"><icon class="icon_legend" name="ban"/>Blocca dominio</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="is_locked" @click="enable_domain"><icon class="icon_legend" name="check"/>Abilita dominio</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="display_header === false" @click="upload_report"><icon class="icon_legend" name="upload"/>Sostituisci pdf</b-dropdown-item-button>
                    <b-dropdown-item-button v-if="display_header === false" @click="queue_report"><icon class="icon_legend" name="arrow-right"/>Invia</b-dropdown-item-button>
                </b-dropdown>
            </div> -->
        </b-card-text>

        <!-- modal pdf -->
        <b-modal
            id="modal-pdf"
            title="Sostituzione pdf"
            button-size="sm"
            >
            <div v-if="upload_active === false">
                <bnvform
                    :key       = "modal_pdf"
                    name       = "replace_pdf"
                    :schema    = "replace_pdf_schema"
                    :options   = "{}"
                    :record    = "{}"
                    :submit    = "false"
                    inline     = "true"
                    layout     = "single"
                    ref        = "replace_pdf_form"
                    @submitted = "submit_pdf"
                ></bnvform>
            </div>
            <div v-if="upload_active">
                <div class="text-center">
                    <div class="spinner-grow text-dark" role="status" style="width: 6rem; height: 6rem;">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
            <template v-if="upload_active === false" v-slot:modal-footer="{ ok, cancel, hide }">
                <b-row><b-col>
                    <b-button size="sm" variant="success" @click="submit_pdf_form">
                      OK
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="warning" @click="hide('clear')">
                      Reset
                    </b-button>
                </b-col><b-col>
                    <b-button size="sm" variant="outline-secondary" @click="cancel()">
                      Chiudi
                    </b-button>
                </b-col></b-row>
            </template>
        </b-modal>
    </b-card>

</template>

<style>
    .icon_legend {
        margin-right: 12px;
    }
</style>

