//////////////////////////////

// questo schema è utilizzato dal frontend
// per generare la maschera della rassegna manuale

//////////////////////////////

module.exports = (type) => {
    var schema = {

        rwcompany_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'rwcompanies',
                key         : 'id',
                constraints : true,
                store_name  : 'rwcompany',
            }
        },
        links: {
            type: type.TEXT,
            allowNull: false,
        },

    };

    return schema;
}



