//////////////////////////////

module.exports = (type) => {
    var schema = {
        date: {
            type: type.DATEONLY,
            allowNull: false,
            defaultValue: type.NOW,
        },
        config: {
            type: type.JSON,
            allowNull: false,
            defaultValue: {},
        },
        state: {
            type: type.STRING(64),
            allowNull: false,
            defaultValue: 'inactive',
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}

