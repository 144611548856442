<script>

const nprogress = require('nprogress');
const moment = require('moment')

export default {
    name: 'rw-thumnails',
    props: [ 'item', 'read_only' ],
    components: {},
    mounted() {},
    watch: {
    },
    computed: {
        store() { return this.$store.state[ this.schema_name ] },
        pages: {
            get() { return this.map.get( this.item.id ).data.pages; },
        },
        map() { return this.store.map; },
    },
    methods: {
        set_page_value( index, value ) {
            this.$store.dispatch( `${ this.schema_name }/set_page`, {
                item_id    : this.item.id,
                page_index : index,
                value
            });
        },
        set_selected() {
            this.$store.dispatch( `${ this.schema_name }/set_selected`, this.item );
        },
        manage_page( page_index ) {
            if ( this.read_only === true ) { return; }
            this.set_page_value( page_index, !this.pages[ `page${ page_index }` ] );
        },
        is_selected( page_index ) {
            if ( this.pages.hasOwnProperty( `page${ page_index }` ) === false ) { return false; }
            return this.pages[ `page${ page_index }` ];
        },
    },
    created() {
        this.loaded = true;
    },
    data () {
        return {
            schema_name : 'rwreport',
            loaded      : false,
        };
    },
};

</script>

<template>

    <b-row v-if="item.data.thumbnails_url.length > 0 && loaded === true">
        <b-col
            cols="4"
            style="margin-bottom: 15px;"
            v-for="( thumb, index ) in item.data.thumbnails_url"
            @click="manage_page( index )"
            :key="'page' + index"
            :style="{ 'cursor': read_only === false ? 'pointer' : 'default' }"
            >
            <b-img thumbnail fluid :src="thumb" :alt="`Thumbnail ${ index + 1 }`" :style="{ 'opacity': is_selected( index ) ? 1 : 0.2 }"></b-img>
            <div class="text-center"><b-badge>{{ `pagina ${ index + 1 }` }}</b-badge></div>
        </b-col>
    </b-row>
    <div v-else-if="item.data.thumbnails_url.length === 0"><div class="text-center">Selezione delle pagine non disponibile</div></div>

</template>

<style>
</style>
