//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(1024),
            allowNull: false,
            set( value ) {
                var label = value.toLowerCase();
                this.setDataValue('label', label);
            },
        },
        rwcompany_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'rwcompanies',
                key         : 'id',
                constraints : true,
                store_name  : 'rwcompany',
            }
        },
        deleted: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        config: {
            type: type.JSON,
            allowNull: false,
            defaultValue: { case_insensitive: true },
        },
        case_insensitive: {
            type: type.VIRTUAL,
            allowNull: false,
            boolean: true,
            defaultValue: true,
        },
        exact_search: {
            type: type.VIRTUAL,
            allowNull: false,
            boolean: true,
            defaultValue: false,
        },
        gsearch: {
            type: type.VIRTUAL,
            boolean: true,
            defaultValue: false,
        },
        gnews: {
            type: type.VIRTUAL,
            boolean: true,
            defaultValue: false,
        },
        enabled: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: true,
        },
        created_by: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'users',
                key         : 'id',
                constraints : true,
                store_name  : 'user',
            }
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}
