//////////////////////////////

import Vue from 'vue';
import Vuex from 'vuex';

import base_module from '@/vue-model-mirror/stores/base.module';

import extraction       from '@/store/extraction.module';
import authentication   from '@/store/authentication.module';
import route            from '@/store/route.module';
import companyall       from '@/store/companyall.module';
import keyword          from '@/store/keyword.module';
import rwkeyword        from '@/store/rwkeyword.module';
import rwreport         from '@/store/rwreport.module';
import rwcompany        from '@/store/rwcompany.module';
import rwassignment     from '@/store/rwassignment.module';
import rwassignment_all from '@/store/rwassignment_all.module';
import search_keyword   from '@/store/search_keyword.module';
import search_rwkeyword from '@/store/search_rwkeyword.module';
import { websocket_logger } from '@/store/websocket_logger.module';
import { alert }        from '@/store/alert.module'; // ................. da sistemare e implementare per tutti

// not default included stores
var vuex_conf = {
    modules: {
        extraction, keyword, rwkeyword, rwreport, rwcompany, rwassignment, rwassignment_all,
        alert, authentication, route, companyall, search_keyword, search_rwkeyword,
        websocket_logger,
    }
};

// optionals settings for default included stores
var conf = {
    user           : { default_order : 'last_name DESC', },
    role           : { default_order : 'label ASC', },
    customer       : { default_order : 'label ASC', },
    company        : { default_order : 'label ASC', },
    rwqueue_item   : { default_order : 'id DESC', },
};

// default included stores (based on schema name)
const schemasobj = require.context( '@/schemas', true, /^.*\.schema.js$/ );
var schemas = schemasobj.keys();
for ( var i = 0; i < schemas.length; i++ ) {
    var schema_name = schemas[ i ].replace(/^\.\//, '').replace(/\.schema\.js$/, '');
    if ( vuex_conf.modules.hasOwnProperty( schema_name ) === false ) {
        var options = {
            model_name    : schema_name,
            send_alert    : false,
        };
        if ( conf.hasOwnProperty( schema_name ) === true && conf[ schema_name ].hasOwnProperty( 'default_order' ) ) {
            options.default_order = conf[ schema_name ].default_order;
        }
        if ( schema_name === 'ispezione' ) { console.log( options ); }
        var base = new base_module( options );
        vuex_conf.modules[ schema_name ] = {
            namespaced : true,
            ...base,
        };
    }
}

Vue.use(Vuex);
export const store = new Vuex.Store( vuex_conf );

