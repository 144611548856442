<script>

const nprogress = require('nprogress');
const moment = require('moment')

import thumbnails from '@/rwreport/thumbnails';
import bnvform from '@/vue-model-mirror/bnvform';
const schemas = require('@/schemaconfig');

export default {
    name: 'rw-tabs',
    props: [ 'item', 'read_only' ],
    components: {
        thumbnails,
        bnvform,
    },
    mounted () {
    },
    watch: {
        item( old_value, new_value ) {
            this.orazio_key++;
        }
    },
    computed: {
//      store() { return this.$store.state[ this.schema_name ] },
//      selected_id() { return this.store.selected_item.id; },
//      is_selected() { return this.selected.item === this.item.id },
//      datetime() { return moment( this.item.created, 'YYYY-MM-DD HH:mm:ss.sssZ' ).format('DD/MM/YYYY - HH:mm'); },
    },
    methods: {
        set_active_tab( tab_index ) {
            this.active_tab = tab_index;
        },
        save_orazio() {
            this.$refs.orazio_form.submit_form();
            if ( this.$refs.orazio_form.is_valid === true ) {
                this.$store.dispatch( `${ this.schema_name }/set_orazio_form`, {
                    item_id : this.item.id,
                    payload : this.$refs.orazio_form.form_data,
                });
            }
        }
    },
    created() {
    },
    data () {
        return {
            schema_name   : 'rwreport',
            active_tab    : 0,
            orazio_schema : schemas.orazio,
            orazio_key    : 0,
        };
    },
};

</script>

<template>

    <b-card body-class="text-center" header-tag="nav">

        <template #header>
            <b-nav card-header tabs>
                <b-nav-item :active="active_tab === 0" @click="set_active_tab(0)">Anteprima</b-nav-item>
                <b-nav-item :active="active_tab === 1" @click="set_active_tab(1)">Pagine</b-nav-item>
                <b-nav-item :active="active_tab === 2" @click="set_active_tab(2)">Dati Orazio</b-nav-item>
                <b-nav-item :active="active_tab === 3" @click="set_active_tab(3)">Log</b-nav-item>
            </b-nav>
        </template>

        <b-card-body v-if="active_tab === 0">
            <b-img v-if="item.data.screenshot_url" fluid :src="item.data.screenshot_url" alt="Image 1"></b-img>
            <div v-if="!item.data.screenshot_url" class="text-center">Anteprima non disponibile</div>
        </b-card-body>
        <b-card-body v-else-if="active_tab === 1">
            <thumbnails :item="item" :read_only="read_only"></thumbnails>
        </b-card-body>
        <b-card-body v-else-if="active_tab === 2">
            <div class="text-left" style="width: 70%; margin: auto;">
                <bnvform
                    name       = "orazio"
                    :schema    = "orazio_schema"
                    :options   = "{}"
                    :record    = "item.data.orazio"
                    :submit    = "false"
                    ref        = "orazio_form"
                    :key       = "orazio_key"
                    :inline    = "true"
                    layout     = "single"
                ></bnvform>
                <b-button variant="success" :disabled="read_only" @click="save_orazio">Salva</b-button>
            </div>
        </b-card-body>
        <b-card-body v-else-if="active_tab === 3">
            <b-container fluid>
                <b-list-group class="text-left">
                    <b-list-group-item
                        :variant="row.includes('error') ? 'danger' : undefined"
                        v-for="( row, index ) in item.data.messages"
                        class="d-flex justify-content-between align-items-center"
                        :key="'msgrow' + index"
                        >
                        {{ row }}
                        <b-badge pill>{{ index + 1 }}</b-badge>
                    </b-list-group-item>
                </b-list-group>
            </b-container>
        </b-card-body>

    </b-card>

</template>

<style>
</style>

