<script>

export default {

    name: 'keyword-delete',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {},
    methods: {
        delete_shop() {
            this.$bvModal.msgBoxConfirm('Confermi l\'eliminazione eliminare della parola chiave "' + this.item.label + '"?')
                .then(value => {
                    if ( value === true ) {
                        this.$store.dispatch( 'keyword/destroy', this.item.id );
                    }
                })
                .catch(err => {
                    console.log( err );
                });
        },
    },
    created () {
    },

}

</script>

<template>

    <div class="text-center" style="padding-top: 10px">
        <b-button size="sm" variant="outline-danger" @click="delete_shop"><b-icon-trash></b-icon-trash> elimina la Parola Chiave</b-button>
    </div>

</template>



