////////////////////

import base_module from '@/vue-model-mirror/stores/base_v2.module';

var options = {
    model_name    : 'rwkeyword',
    send_alert    : false,
    default_order : 'label ASC',
};

var base = new base_module( options );

const state = {
    ...base.state,
    filters       : {},
    amount_studio : 0,
    amount_users  : 0,
    pras_involved : [],
    erased        : false,
};

const getters = {
    ...base.getters,
}

const actions = {
    ...base.actions,
    get_all( { commit, state, getters }, _data = { options: {} } ) { // ok
        var data = JSON.parse( JSON.stringify( _data ) ); // sad but necessary
        commit('start_request');
        data.options.order = getters.order;
        Object.keys( state.filters ).map( key => {
            data.options[ key ] = [ 'label', ].includes( key ) ? `|${ state.filters[ key ] }|` : state.filters[ key ];
        });
        data.options.deleted = false;
        //if ( state.items === null || state.force_reload === true || ( data.hasOwnProperty('force_reload') && data.force_reload === true ) ) {
            state.service.get_all(getters.model_name, data.options)
                .then( response => {
                    if ( data.options.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        //}
    },
    destroy( { commit }, id ) {
        console.log('ciao');
        commit('set_erased', false);
        //commit('start_request');
        state.service.destroy('rwkeyword', id)
            .then( obj => {
                commit('success_destroy', obj)
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
    ...base.mutations,
    success_destroy( state, obj ) {
        state.erased   = true;
        var index = null;
        for ( var i = 0; i < state.items.length; i++ ) {
            if ( state.items[ i ].id === obj.id ) { index = i; break; }
        }
        if ( index ) { delete state.items[ i ]; }
    },
    set_erased( state, value ) {
        state.erased = value;
    },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.filters       = {};
        state.erased        = false;
    },
}

export default {
    namespaced : true,
    ...base,
    state,
    actions,
    mutations,
    getters,
};
