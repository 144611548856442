///////////////////////////////////////

// GESTORE LOG DA WEBSOCKET

const axios = require('axios');

const config = require('@/config.js')[ process.env.NODE_ENV || 'test' ];
import { authHeader } from '@/helpers';

export const websocket_logger = {
    namespaced: true,
    state: {
        message                   : 0,
        variant                   : '',
        loader                    : false,
        ws                        : null,
        wspath                    : null,
        reset_assignment          : null,
        free_involved_association : null,
        // rassegna manuale
        ws_rama      : null,
        wspath_rama  : null,
        message_rama : null,
        rama_loader  : false,
    },
    actions: {
        set_path_rama( { commit, state, dispatch }, data ) {
            var url = config.backend_url + 'model/rwreport/rama';
            var options = { headers: authHeader() };
            commit('set_path_rama', data.wspath );
            axios.post( url, data.payload, options )
                .then( response => {
                    if ( response.status === 200 ) {
                        dispatch('start_rama');
                    }
                })
                .catch( error => { console.log( error ); } );
        },
        start_rama( { commit, state, dispatch } ) {
            if ( state.ws_rama !== null ) { return; }
            try {
                //console.log('try connect...');
                if ( config.backend_url.includes( 'https' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + state.wspath_rama );
                } else if ( config.backend_url.includes( 'http' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + state.wspath_rama );
                }
            } catch ( error ) {
                //console.log('connection error');
                setTimeout( () => { state.ws_rama = null; dispatch('start_rama') }, 500 );
            }
            console.log('connection rama ok');
            commit('set_ws_rama', ws );
            ws.onmessage = msg => {
                var message = JSON.parse(msg.data);
                commit('in_message_rama', message);
            }
            commit( 'in_message_rama', { action: 'inizializzazione', class: 'Rassegna Manuale' } );
            commit('set_rama_loader');
            ws.onclose = event => {
                console.log('connection rama closed');
            };
        },
        set_path( { commit, state, dispatch }, wspath ) {
            commit('set_path', wspath );
            if ( state.ws !== null ) { return; }
            try {
                //console.log('try connect...');
                if ( config.backend_url.includes( 'https' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('https://', 'wss://') + state.wspath );
                } else if ( config.backend_url.includes( 'http' ) ) {
                    var ws = new WebSocket( config.backend_url.replace('http://', 'ws://') + state.wspath );
                }
            } catch ( error ) {
                //console.log('connection error');
                setTimeout( () => { state.ws = null; dispatch('set_path', wspath) }, 500 );
            }
            console.log('connection ok');
            commit('set_ws', ws );
            ws.onmessage = msg => {
                var message = JSON.parse(msg.data);
                if ( message.action === 'item_sent' ) {
                    dispatch( 'rwreport/override_item', message.rwreport, { root: true } );
                }
                commit('in_message', message);
            }
            ws.onclose = event => {
                //console.log('connection lost');
                setTimeout( () => {  state.ws = null; dispatch('set_path', wspath) }, 500 );
            };
        },
        reset( { commit }, message_class ) { commit('reset', message_class); },
    },
    mutations: {
        set_path( state, wspath ) { state.wspath = wspath; },
        set_path_rama( state, wspath ) { state.wspath_rama = wspath; },
        set_ws( state, ws ) { state.ws = ws; },
        set_ws_rama( state, ws ) { state.ws_rama = ws; },
        set_rama_loader( state, value = true ) { state.rama_loader = value },
        in_message( state, message ) {
            console.log( message );
            if ( message.hasOwnProperty('free_involved_rwassocation') ) {
                state.free_involved_association = message.index;
                setTimeout( () => { state.free_involved_association = null }, 200 );
            } else if ( message.hasOwnProperty('reset_assignment') ) {
                state.reset_assignment = message.company_id;
                setTimeout( () => { state.reset_assignment = null }, 200 );
            } else if ( message.hasOwnProperty('start') === true ) {
                state.loader = true;
            } else if ( message.hasOwnProperty('stop') === true ) {
                state.loader = false;
                //state.ws.close();
                //state.ws = null;
            } else {
                state.loader = true;
            }
            if ( message.level === 'info' ) {
                state.variant = '';
                if ( message.hasOwnProperty('success') === true ) {
                    state.variant = 'success';
                }
            } else if ( message.level === 'notice' ) {
                state.variant = 'warning';
            } else if ( message.level === 'crit' ) {
                state.variant = 'danger';
                state.loader = false;
            } else if ( message.level === 'error' ) {
                state.variant = 'danger';
                state.loader = false;
            }
            state.message = message;
        },
        in_message_rama( state, message ) {
            console.log( message );
            if ( message.hasOwnProperty('start') === true ) {
                state.rama_loader = true;
            } else if ( message.hasOwnProperty('stop') === true ) {
                setTimeout( () => {
                    state.rama_loader = false;
                    state.ws_rama.close();
                    state.ws_rama = null;
                    state.ws = null;
                    state.rama_loader = false;
                }, 10000);
            }
            state.message_rama = message;
        },
        reset( state, message_class ) {
            state.loader         = true;
            state.message        = { 'class': message_class, action: 'Inizializzazione in corso' };
            state.variant        = '';
        },
    }
}
