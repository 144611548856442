<script>

const nprogress = require('nprogress');
const moment = require('moment')

export default {
    name: 'rw-report-card-list',
    props: [ 'item' ],
    components: {
    },
    mounted () {
    },
    watch: {
    },
    computed: {
        store() { return this.$store.state[ this.schema_name ] },
        selected_id() { return this.store.selected_item ? this.store.selected_item.id : null; },
        is_selected() { return this.selected_id === this.item.id },
        modify_border() {
            return this.item.has_error === true || this.item.state !== null;
        },
        border_variant() {
            if ( this.modify_border === false ) { return undefined; }
            if ( this.item.has_error ) { return 'warning'; }
            if ( this.item.state !== null ) { return this.item.state === 'error' ? 'danger' : 'success'; }
        },
    },
    methods: {
        set_selected() {
            this.$store.dispatch( `${ this.schema_name }/set_selected`, this.item );
        }
    },
    created() {
    },
    data () {
        return {
            schema_name : 'rwreport',
        };
    },
};

</script>

<template>

    <b-card
        style="margin-bottom: 10px; cursor: pointer;"
        tag="article"
        :style="{ 'border-width': item.has_error ? '3px' : '3px' }"
        :bg-variant="is_selected ? 'dark' : undefined"
        :text-variant="is_selected ? 'white': undefined"
        :border-variant="border_variant"
        @click="set_selected"
        >
        <b-card-title v-if="item.title" style="font-size:13pt; font-weight: 600;">{{ item.title }}</b-card-title>
        <b-card-title v-else-if="!item.title" style="font-size:13pt; font-weight: 500; color: grey">titolo non disponibile</b-card-title>
        <b-card-text>
                <b-row>
                    <b-col cols="9"><div style="background-color: #EAECEE; padding: 3px; font-size: 9pt; border-radius: 5px; color: black;"><b-icon icon="play-fill" aria-hidden="true"></b-icon>&nbsp;{{ item.data.domain }}</div></b-col>
                    <b-col cols="3"><div style="background-color: #ABEBC6; padding: 3px; font-size: 9pt; border-radius: 5px; font-weight: 700; color: black;" class="text-center"><b-icon icon="file-earmark" aria-hidden="true"></b-icon>&nbsp;{{ item.data.page_count }}</div></b-col>
                </b-row>
                <b-row style="margin-top: 8px;">
                    <b-col cols="12">
                        <div v-if="!item.data.manual" style="background-color: #EAECEE; padding: 3px; font-size: 9pt; border-radius: 5px; color: black;"><b-icon icon="key-fill" aria-hidden="true"/>&nbsp;{{ item.keyword_label }}</div>
                        <div v-else-if="item.data.manual" style="background-color: #E6CCFF; padding: 3px; font-size: 9pt; border-radius: 5px; color: black;"><b-icon icon="bookmark-heart-fill" aria-hidden="true"/>&nbsp;rassegna manuale</div>
                    </b-col>
                </b-row>
        </b-card-text>
    </b-card>

</template>

