//////////////////////////////

module.exports = (type) => {
    var schema = {
        file: {
            type: type.VIRTUAL,
            file: true,
            allowNull: false,
            max_size: 6000000,
            filter: 'pdf',
            helper: 'sono ammessi file pdf fino a 6MB'
        },
    };

    return schema;
}

