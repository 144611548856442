////////////////////////////////////////

const user             = require('@/schemas/user.schema.js');
const role             = require('@/schemas/role.schema.js');
const extraction       = require('@/schemas/extraction.schema.js');
const customer         = require('@/schemas/customer.schema.js');
const keyword          = require('@/schemas/keyword.schema.js');
const company          = require('@/schemas/company.schema.js');
const channel          = require('@/schemas/channel.schema.js');
const site             = require('@/schemas/site.schema.js');
const search_keyword   = require('@/schemas/search_keyword.schema.js');
const add_youtube_link = require('@/schemas/add_youtube_link.schema.js');
// rassegna web
const rwcompany        = require('@/schemas/rwcompany.schema.js');
const rwkeyword        = require('@/schemas/rwkeyword.schema.js');
const search_rwkeyword = require('@/schemas/search_rwkeyword.schema.js');
const orazio           = require('@/schemas/orazio.schema.js');
const rwassignment     = require('@/schemas/rwassignment.schema.js');
const replace_pdf      = require('@/schemas/replace_pdf.schema.js');
const rassegna_manuale = require('@/schemas/rassegna_manuale.schema.js');

module.exports = {
    user,
    role,
    extraction,
    customer,
    keyword,
    company,
    channel,
    site,
    search_keyword,
    add_youtube_link,
    rwkeyword,
    rwcompany,
    search_rwkeyword,
    orazio,
    rwassignment,
    replace_pdf,
    rassegna_manuale,
};

