<script>

import bnvform from '@/vue-model-mirror/bnvform';
import { formpage } from '@/vue-model-mirror';

const schemas = require('@/schemaconfig');

export default {
    name: 'extraction-form',
    extends: formpage,
    components: {
        bnvform,
    },
    methods: {
        operation_success: function( data ) {
            this.$store.dispatch('extraction/push_to_queue', data.file);
            var file_number = data.file.length;
            if ( file_number > 0 ) {
                var title = 'Coda di upload';
                var body = `${ file_number } file aggiunti`;
                this.make_toast( title.toUpperCase(), body );
                this.$store.dispatch('extraction/upload_queue');
                this.$refs.bnvform.reset_form();
            }
        },
        make_toast(title, body) {
            this.$bvToast.toast( body, {
                title         : title,
                autoHideDelay : 2500,
                appendToast   : true,
            });
        },
    },
    data () {
        return {
        }
    },
};

</script>

<template>

    <div>
        <h3 style="text-align: center; padding-bottom: 14px;">{{ title }}</h3>
        <bnvform
            v-if       = "started === true"
            ref        = "bnvform"
            :name      = "schema_name"
            :schema    = "schema"
            :options   = "options"
            :record    = "record"
            :inline    = "inline"
            :submit    = "false"

            :key       = "form_reload"
            @reset     = "reset_form"
            @submitted = "operation_success"
            @saved     = "operation_success"
        ></bnvform>
    </div>

</template>

