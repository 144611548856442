<script>

export default {
    name: 'bnvbase',
    props: [
        'elementtype','name', 'value', 'label', 'allownull', 'errormessage', 'recordid', 'disabled',
        'readonly', 'edit', 'size', 'helper', 'search', 'minlength', 'maxlength', 'precision', 'decimal', 'filter', 'config', 'maxvalue', 
        'upload_progress',
    ],
    components: {},
    computed: {
        _readonly () {
            if ( this.readonly === true ) { return true; }
            if ( this.readonly === 'edit' && this.edit === true ) { return true; }
            if ( this.readonly === 'add' && this.edit === false ) { return true; }
            return false;
        },
        label_class () {
            if ( this.size === 'sm' ) { return 'col-form-label-sm'; }
            if ( this.size === 'lg' ) { return 'col-form-label-lg'; }
            return '';
        },
        input_class () {
            if ( this.size === 'sm' ) { return 'form-control-sm'; }
            if ( this.size === 'lg' ) { return 'form-control-lg'; }
            return '';
        },
        _helper () {
            if ( this.helper ) {
                return this.helper.replace(/\\n/g, '<br/>');
            }
        },
    },
    data () {
        return {
            model           : null,
            error_message   : null,
            error_component : false,
            confirm_message : null,
        }
    },
    created () {
        //console.log( this.max_length );
        this.model = typeof this.value === 'undefined' || this.value === null ? '' : this.value;
    },
    methods: {
        get_label: function() {
            return typeof this.label === 'undefined' ? this.name : this.label;
        },
        get_placeholder: function() {
            return typeof this.placeholder === 'undefined' ? undefined : this.placeholder;
        },
        set_value: function (value) { // public
            if ( !this.model ) {
                this.model = value;
            }
        },
        get_value: function() { // public
            this.error_component = false;
            this.error_message = null;
            var check = this._validate();
            if ( check === false ) { return undefined; }
            var value = this._return_model();
            if ( value === null ) { return null; }
            if ( this.search ) {
                var res = { value, search: null, };
                if ( this.search === 'contains_before_after' ) { res.search = value ? `|${ value }|` : null; }
                return res;
            }
            return value;
        },
        _return_model() {
            return this.model ? this.model : null;
        },
        reseta() { // public - experimental 14/03/20
            this.model = '';
        },
        _validate: function() {
            var check = this._validate_allow_null();
            return check;
        },
        _validate_allow_null: function() {
            if ( this.allownull === false ) {
                if ( this.model === '' || typeof this.model === 'undefined' || this.model === null ) {
                    this.error_message = 'campo obbligatorio';
                    this.error_component = true;
                    return false;
                }
            }
            return true;
        },
        get_error_message() {
            if ( typeof this.errormessage !== 'undefined' ) {
                this.error_message = this.errormessage;
                this.error_component = true;
            }
            return this.error_message;
        },
        set_confirm_message(text) {
            this.confirm_message = text;
            setTimeout( () => {
                this.confirm_message = null;
            }, 3000)
        },
        value_changed() {
            var payload = {};
            payload[ this.name ] = this.model;
            this.$emit('change', payload );
        },
        enter_key() {
            this.$emit('enter_key');
        },
    },
};

</script>

<template>
    <div></div>
</template>



