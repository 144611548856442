//////////////////////////////

module.exports = (type) => {
    var schema = {
        rwcompany_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'rwcompanies',
                key         : 'id',
                constraints : true,
                store_name  : 'rwcompany',
            }
        },
        rwkeyword_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'rwkeywords',
                key         : 'id',
                constraints : true,
                store_name  : 'rwkeyword',
            }
        },
        title: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        date: {
            type: type.DATEONLY,
            allowNull: false,
            defaultValue: type.NOW,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        keyword_label: {
            type: type.STRING(64),
            allowNull: false,
        },
        has_error: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: false,
        },
        error: {
            type: type.TEXT,
            allowNull: true,
            defaultValue: null,
        },
        link: {
            type: type.STRING(1024),
            allowNull: false,
        },
        data: {
            type: type.JSON,
            allowNull: null,
        },
        ready: {
            type: type.INTEGER,
            allowNull: false,
            defaultValue: 2,
        },
        id: {
          type: type.INTEGER,
          primaryKey: true,
          autoIncrement: true
        },
    };

    return schema;
}

