<script>

export default {

    name: 'rwkeyword-stype',
    props: [ 'item', ],
    data() {
        return {
        }
    },
    computed: {
        stype() {
            if ( this.item.config.hasOwnProperty('gsearch') === false ) {
                return { gsearch: true, gnews: true };
            }
            return {
                gsearch : this.item.config.gsearch,
                gnews   : this.item.config.gnews,
            };
        },
        gsearch() { return this.stype.gsearch; },
        gnews() { return this.stype.gnews; },
    },
    methods: {
    },
    created () {
    },

}

</script>

<template>

    <b-col style="">
        <b-row :style="{ 'color': gsearch ? 'black' : '#717171', 'opacity': gsearch ? '1' : '0.7' }">
            <div style="width: 20px;"><icon style="" :name="stype.gsearch ? 'check-square' : 'ban'"/></div><div>Google</div>
        </b-row>
        <b-row :style="{ 'color': gnews ? 'black' : '#717171', 'opacity': gnews ? '1' : '0.7' }">
            <div style="width: 20px;"><icon style="" :name="stype.gnews ? 'check-square' : 'ban'"/></div><div>Google News</div>
        </b-row>
    </b-col>

</template>


