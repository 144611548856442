//////////////////////////////

module.exports = (type) => {
    var schema = {
        label: {
            type: type.STRING(1024),
            allowNull: false,
            unique: true,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue: type.NOW,
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        config: {
            type: type.JSON,
            allowNull: false,
            defaultValue: {},
        },
//      channel_id: {
//          type: type.VIRTUAL,
//          allowNull: false,
//      },
//      must_contain: {
//          type: type.VIRTUAL,
//          allowNull: false,
//          tag: true,
//      },
        active: {
            type: type.BOOLEAN,
            allowNull: false,
            defaultValue: true,
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

